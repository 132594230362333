import './Layout.scss';
import Header from '../../components/Header/Header';
import { Outlet } from "react-router-dom";
import Footer from '../../components/Footer/Footer';

function Layout() {
  return (
    <div className="Layout">
      <Header />
      <Outlet />
      <Footer />
    </div>
  );
}

export default Layout;