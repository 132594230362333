import { React, useState, useEffect } from 'react';
import axios from "axios";
import { useSelector } from 'react-redux';
import Loading from '../../components/Loading/Loading';
import MessageModal from '../../components/MessageModal/MessageModal';
import Carousel from '../../components/Carousel/Carousel';
import CarouselImageList from '../../config/carouselImageList';
import Constant from '../../config/constant';
import i18n from '../../config/i18n';
import './Customize.scss';

const Customize = () => {
    const [showLoadingSpinner, setShowLoadingSpinner] = useState(false);
    const [showAlertModal, setShowAlertModal] = useState(false);
    const [alertMessage, setAlertMessage] = useState({ type: '', message: '' });
    const displayLanguage = useSelector(state => state.displayLanguage);
    const modalHandler = (show) => setShowAlertModal(show);
    const send = (e) => {
        let check = false;
        let url = `${Constant.apiHost}api/UserMessage/SaveCustomizeMessage`;
        let requestData = {
            companyName: document.querySelector('form input[name="companyName"]').value,
            userName: document.querySelector('form input[name="userName"]').value,
            email: document.querySelector('form input[name="email"]').value,
            productCategory: document.querySelector('form input[name="category"]:checked') ? document.querySelector('form input[name="category"]:checked').value : null,
            productLevel: document.querySelector('form input[name="level"]').value,
            hasExperience: document.querySelector('form input[name="experience"]:checked') ? document.querySelector('form input[name="experience"]:checked').value : null,
            country: document.querySelector('form input[name="country"]:checked') ? document.querySelector('form input[name="country"]:checked').value : null,
            remark: document.querySelector('form textarea[name="remark"]').value,
            message: document.querySelector('form textarea[name="message"]').value
        };

        e.preventDefault();
        setShowLoadingSpinner(true);

        if (!requestData.userName) {
            setAlertMessage({ type: 'alert', message: '請填寫「名稱」欄位！' });
        } else if (!requestData.email) {
            setAlertMessage({ type: 'alert', message: '請填寫「E-mail」欄位！' });
        } else if (!requestData.hasExperience) {
            setAlertMessage({ type: 'alert', message: '請選擇「是否有使用類似之產品」！' });
        } else if (!requestData.message) {
            setAlertMessage({ type: 'alert', message: '請填寫「內容描述」！' });
        } else if (!requestData.email.match(/^([\w\.\-]+)@([\w\-]+)((\.(\w){2,3})+)$/g)) {
            setAlertMessage({ type: 'alert', message: '請輸入正確的 E-mail 格式！' });
        } else if (requestData.productCategory && requestData.productCategory === 'Other' && !document.querySelector('form input[name="categoryOther"]').value) {
            setAlertMessage({ type: 'alert', message: '請輸入「客製化類別」的「其他」欄位！' });
        } else if (requestData.country && requestData.country === 'Other' && !document.querySelector('form input[name="countryOther"]').value) {
            setAlertMessage({ type: 'alert', message: '請輸入「國家」的「其他」欄位！' });
        } else {
            check = true;
        }

        if (requestData.productCategory && requestData.productCategory === 'Other') requestData.productCategory += '-' + document.querySelector('form input[name="categoryOther"]').value;
        if (requestData.country && requestData.country === 'Other') requestData.country += '-' + document.querySelector('form input[name="countryOther"]').value;
        if (requestData.hasExperience === 'Y') requestData.hasExperience = true;
        else if (requestData.hasExperience === 'N') requestData.hasExperience = false;

        console.log(requestData);

        if (!check) {
            setShowAlertModal(true);
            setShowLoadingSpinner(false);
        } else {
            axios.post(url, requestData)
                .then(response => {
                    if (response.data.code === '0000') {
                        setAlertMessage({ type: 'success', message: '訊息送出完成！' });
                        setShowAlertModal(true);

                        document.querySelector('form input[name="companyName"]').value = '';
                        document.querySelector('form input[name="userName"]').value = '';
                        document.querySelector('form input[name="email"]').value = '';
                        document.querySelector('form input[name="categoryOther"]').value = '';
                        document.querySelector('form input[name="level"]').value = '';
                        document.querySelector('form input[name="countryOther"]').value = '';
                        document.querySelector('form textarea[name="remark"]').value = '';
                        document.querySelector('form textarea[name="message"]').value = '';

                        let category = document.querySelector('form input[name="category"]:checked');
                        let hasExperience = document.querySelector('form input[name="experience"]:checked');
                        let country = document.querySelector('form input[name="country"]:checked');

                        if(category) category.checked = false;
                        if(hasExperience) hasExperience.checked = false;
                        if(country) country.checked = false;
                    } else {
                        setAlertMessage({ type: 'error', message: '送出訊息時發生錯誤，請稍後再試...' });
                        setShowAlertModal(true);
                    }
                })
                .catch(error => {
                    console.error(error.message);

                    setAlertMessage({ type: 'error', message: '送出訊息時發生錯誤，請稍後再試...' });
                    setShowAlertModal(true);
                })
                .finally(() => setShowLoadingSpinner(false));
        }
    };
    let viewText = i18n[displayLanguage.code].customize;

    useEffect(() => {
        viewText = i18n[displayLanguage.code].customize;
    }, [displayLanguage]);

    return (
        <div className='Customize'>
            {
                showLoadingSpinner ? <Loading /> : ''
            }
            {
                showAlertModal ? <MessageModal message={alertMessage} handler={modalHandler} /> : ''
            }
            <Carousel slideList={CarouselImageList.customize} />
            <form>
                <div className='form-row'>
                    <label>{viewText.formColumns.company.title}</label>
                    <input type='text' name='companyName'></input>
                </div>
                <div className='form-row'>
                    <label className='mark'>{viewText.formColumns.username.title}</label>
                    <input type='text' name='userName'></input>
                </div>
                <div className='form-row'>
                    <label className='mark'>{viewText.formColumns.email.title}</label>
                    <input type='text' name='email'></input>
                </div>
                <div className='form-row'>
                    <label>{viewText.formColumns.productCategory.title}</label>
                    <div className='input-group flex'>
                        <div className='input-item'>
                            <input type='radio' value='PP' name='category'></input>
                            {viewText.formColumns.productCategory.items.PP.text}
                        </div>
                        <div className='input-item'>
                            <input type='radio' value='PE' name='category'></input>
                            {viewText.formColumns.productCategory.items.PE.text}
                        </div>
                        <div className='input-item'>
                            <input type='radio' value='Other' name='category'></input>{viewText.formColumns.productCategory.items.other.text}
                            <input type='text' className='input-other' name='categoryOther'></input>
                        </div>
                    </div>
                </div>
                <div className='form-row'>
                    <label>{viewText.formColumns.productLevel.title}</label>
                    <input type='text' name='level'></input>
                </div>
                <div className='form-row'>
                    <label className='mark'>{viewText.formColumns.hasExperience.title}</label>
                    <div className='input-group'>
                        <input type='radio' value='Y' name='experience'></input>{viewText.formColumns.hasExperience.items.true.text}
                        <input type='radio' value='N' name='experience'></input>{viewText.formColumns.hasExperience.items.false.text}
                    </div>
                </div>
                <div className='form-row'>
                    <label >{viewText.formColumns.country.title}</label>
                    <div className='input-group flex'>
                        <div className='input-item'>
                            <input type='radio' value='Taiwan' name='country'></input>
                            {viewText.formColumns.country.items.taiwan.text}
                        </div>
                        <div className='input-item'>
                            <input type='radio' value='Vietnem' name='country'></input>
                            {viewText.formColumns.country.items.vietnam.text}
                        </div>
                        <div className='input-item'>
                            <input type='radio' value='Other' name='country'></input>
                            {viewText.formColumns.country.items.other.text}
                            <input type='text' className='input-other' name='countryOther'></input>
                        </div>
                    </div>
                </div>
                <div className='form-row'>
                    <label>{viewText.formColumns.remark.title}</label>
                    <textarea name='remark'></textarea>
                </div>
                <div className='form-row'>
                    <label className='mark'>{viewText.formColumns.message.title}</label>
                    <textarea name='message'></textarea>
                </div>
                <div className='form-row submit' onClick={send}>
                    <button>{viewText.buttons.send.text}</button>
                </div>
            </form>
        </div>
    )
};

export default Customize;