import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setLanguage } from '../../store/DisplayLanguageSlice';
import Flag from 'react-flagpack'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LanguageSelectList from '../LanguageSelectList/LanguageSelectList';
import './LanguageSelector.scss';

const LanguageSelector = () => {
    const languageSelector = useSelector(state => state.displayLanguage);
    const dispatch = useDispatch()
    const [displayLanguage, setDisplayLanguage] = React.useState(languageSelector);
    const [showSelectList, setShowSelectList] = React.useState(false);
    const onClick = () => setShowSelectList(!showSelectList);
    const displayLanguageHandler = (displayLanguage) => {
        dispatch(setLanguage(displayLanguage));
        return setDisplayLanguage(displayLanguage);
    };

    return (
        <div className='languageSelector' onClick={onClick}>
            <div className='languageSelector-country-display'>
                <Flag code={displayLanguage.icon} size="S" />
                <div className='language-name'>{displayLanguage.displayText}</div>
            </div>
            <div className='show-list-button'>
                <FontAwesomeIcon icon={faAngleDown} />
            </div>
            {showSelectList ? <LanguageSelectList handler={displayLanguageHandler} /> : null}
        </div>
    )
};

LanguageSelector.propTypes = {};

LanguageSelector.defaultProps = {};

export default LanguageSelector;