import { React, useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import axios from "axios";
import Moment from 'moment';
import { useSelector } from 'react-redux';
import Loading from '../../components/Loading/Loading';
import { locationPath } from '../../helpers/pathHelper';
import Constant from '../../config/constant';
import i18n from '../../config/i18n';
import './News.scss';

const News = () => {
    const [showLoadingSpinner, setShowLoadingSpinner] = useState(false);
    const [newsList, setNewsList] = useState([]);
    const [displayNewsList, setDisplayNewsList] = useState([]);
    const displayLanguage = useSelector(state => state.displayLanguage);
    let viewText = i18n[displayLanguage.code].news;
    const displayNewsHandler = (data) => {
        let result = data.map(x => {
            let target = x.newsLocaleList.find(x => x.locale === displayLanguage.code);
            if (target) {
                return {
                    id: x.id,
                    category: target.category,
                    imagePath: target.imagePath,
                    title: target.title,
                    createTime: target.createTime
                };
            }
        });

        setDisplayNewsList(result);
    }

    useEffect(() => {
        let url = `${Constant.apiHost}api/News/GetNewsList`;

        setShowLoadingSpinner(true);

        axios.get(url)
            .then(response => {
                if (response.data.code === '0000') {
                    setNewsList(response.data.data);
                    displayNewsHandler(response.data.data);
                }
            })
            .finally(() => setShowLoadingSpinner(false));
    }, []);

    useEffect(() => {
        viewText = i18n[displayLanguage.code].news;
        displayNewsHandler(newsList);
    }, [displayLanguage]);

    return (
        <div className="News">
            {showLoadingSpinner ? <Loading /> : ''}
            <div className='title'>
                <h1>{viewText.title}</h1>
            </div>
            {
                displayNewsList.map((item, index) => {
                    if (item) {
                        return (
                            <div className='news-card' key={index}>
                                <div className='news-image-container'>
                                    {
                                        item.imagePath ?
                                            <div className='image-holder' style={{ backgroundImage: `url(${item.imagePath})` }}></div>
                                            : <div className='image-holder' style={{ backgroundImage: `url(${locationPath}images/news/default.jpg)` }}></div>
                                    }
                                </div>
                                <div className='news-content-container'>
                                    <div>
                                        <div className='news-category'>{item.category}</div>
                                        <div className='news-release-date'>{Moment(item.createTime).format('YYYY-MM-DD')}</div>
                                    </div>
                                    <h2>{item.title}</h2>
                                    <b></b>
                                    <Link to={`/news-detail?id=${item.id}`}>{viewText.buttons.readMore.text}</Link>
                                </div>
                            </div>
                        )
                    }
                })
            }
        </div>
    )
};

export default News;