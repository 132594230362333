import React, { useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from "react-router-dom";
import Select from 'react-select'
import axios from "axios";
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons'
import SideMenu from '../../components/SideMenu/SideMenu';
import { locationPath } from '../../helpers/pathHelper';
import { MenuList_TC, MenuList_EN } from '../../config/menuList';
import Constant from '../../config/constant';
import i18n from '../../config/i18n';
import Loading from '../../components/Loading/Loading';
import './Products.scss';

const Products = () => {
    let navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [showCategoryFilter, setShowCategoryFilter] = useState(false);
    const [showLoadingSpinner, setShowLoadingSpinner] = useState(false);
    const [responseData, setResponseData] = useState([]);
    const [productList, setProductList] = useState([]);
    const displayLanguage = useSelector(state => state.displayLanguage);
    const category = searchParams.get('category');
    const categoryFilterHandler = show => setShowCategoryFilter(show);
    const seeProductDetail = (productId) => { navigate(`/product-detail?id=${productId}`, { replace: false }) };
    const productCategoryList = MenuList_TC[1].subMenuList.map(item => {
        return {
            title: item.title,
            path: item.path
        };
    });
    const productCategoryDropdownList = MenuList_TC[1].subMenuList.map(item => {
        return {
            value: item.path,
            label: item.title
        };
    });
    const selectCategory = (e) => navigate(e.value, { replace: false });
    const productListHandler = (data) => data.map((item) => {
        let localeInfo = item.nameLocaleList.find(x => x.locale === displayLanguage.code);
        if (localeInfo) {
            return {
                id: item.id,
                imagePath: item.imagePath,
                name: localeInfo.name
            };
        }
    });
    let viewText = i18n[displayLanguage.code].productList;

    useEffect(() => {
        let url = `${Constant.apiHost}api/Product/`;

        setShowLoadingSpinner(true);

        url += category ? 'GetProductList?category=' + category : 'GetProductList'

        axios.get(url)
            .then(response => {
                if (response.data.code === '0000') {
                    setResponseData(response.data.data);
                    setProductList(productListHandler(response.data.data));
                } else {
                    setResponseData([]);
                    setProductList([]);
                }
            })
            .finally(() => setShowLoadingSpinner(false));
    }, [category]);

    useEffect(() => {
        viewText = i18n[displayLanguage.code].productList;
        setProductList(productListHandler(responseData));
    }, [displayLanguage]);

    return (
        <div className='Products'>
            {
                showLoadingSpinner ? <Loading /> : ''
            }
            <div className='products-items'>
                <div className='product-category-selector'>
                    <div className='total-result'>{viewText.total.prefix} {productList.length} {viewText.total.suffix}</div>
                    <Select
                        options={productCategoryDropdownList}
                        isSearchable={false}
                        onChange={selectCategory}
                    />
                </div>
                <div className='product-filter-btn'>
                    <button onClick={() => setShowCategoryFilter(true)} ><FontAwesomeIcon icon={faFilter} /> {viewText.filterButton.text}</button>
                    <div className='total-result'>{viewText.total.prefix} {productList.length} {viewText.total.suffix}</div>
                </div>
                {
                    productList.map(item => {
                        if (item) {
                            return (
                                <div className='product' key={item.id} onClick={seeProductDetail.bind(this, item.id)}>
                                    <div className='image-container'>
                                        <img src={`${item.imagePath}`}></img>
                                    </div>
                                    <p>{item.name}</p>
                                </div>
                            )
                        }
                    })
                }
            </div>
            {
                showCategoryFilter ? (
                    <div className='category-selector'>
                        <SideMenu menuList={productCategoryList} handler={categoryFilterHandler} />
                    </div>
                ) : ""
            }
        </div>
    );
};

export default Products;