import React, { useEffect, useState } from 'react';
import { Link, useSearchParams } from "react-router-dom";
import axios from "axios";
import { useSelector } from 'react-redux';
import Loading from '../../components/Loading/Loading';
import { locationPath } from '../../helpers/pathHelper';
import Constant from '../../config/constant';
import i18n from '../../config/i18n';
import './NewsDetail.scss';

const NewsDetail = () => {
    const [searchParams] = useSearchParams();
    const [showLoadingSpinner, setShowLoadingSpinner] = useState(false);
    const [newsDetail, setNewsDetail] = useState({ id: null, newsDetailLocaleList: [] });
    const [displayNewsContent, setDisplayNewsContent] = useState({ category: null, title: null, content: null, attachmentPath: null });
    const displayLanguage = useSelector(state => state.displayLanguage);
    const newsId = searchParams.get('id');
    const openTab = (url) => { window.open(url); }
    let viewText = i18n[displayLanguage.code].newsDetail;
    const displayNewsHandler = (data) => {
        console.log(data);
        console.log(displayLanguage.code);
        let result = data.newsDetailLocaleList.find(x => x.locale === displayLanguage.code);

        if (!result) result = { category: null, title: null, content: null, attachmentPath: null };

        setDisplayNewsContent(result);
    }

    useEffect(() => {
        let url = `${Constant.apiHost}api/News/GetNewsDetail?id=${newsId}`;

        setShowLoadingSpinner(true);

        axios.get(url)
            .then(response => {
                setNewsDetail(response.data.data);
                displayNewsHandler(response.data.data);
            })
            .finally(() => setShowLoadingSpinner(false));
    }, [newsId]);

    useEffect(() => {
        viewText = i18n[displayLanguage.code].newsDetail;
        displayNewsHandler(newsDetail);
    }, [displayLanguage]);

    return (
        <div className='NewsDetail'>
            {
                showLoadingSpinner ? <Loading /> : ''
            }
            <div className='navigation-bar'>
                <Link to='/news'>{viewText.navigation.root.text}</Link> ＞ {viewText.navigation.child.text}
            </div>
            <div className='news-title'>
                <h2>{displayNewsContent.title}</h2>
            </div>
            <b className='divider'></b>
            <div className='image-container'>
                {
                    displayNewsContent.attachmentPath ?
                        <div className='image-holder' style={{ backgroundImage: `url(${displayNewsContent.attachmentPath})`}} onClick={openTab.bind(this, `${displayNewsContent.attachmentPath}`)}></div>
                        : <div className='image-holder' style={{ backgroundImage: `url(${newsDetail.imagePath})` }}></div>
                }
            </div>
            <div className='detail-content'>
                <pre>{displayNewsContent.content}</pre>
            </div>
            <div className='button-bar'>
                <Link to='/news'>{viewText.buttons.back.text}</Link>
            </div>
        </div>
    );
};

export default NewsDetail;