import React, { useState } from 'react';
import { Link } from "react-router-dom";
import './MenuItem.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import DropDownItem from '../DropDownItem/DropDownItem';

const MenuItem = ({ menuItem }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    return (
        <>
            {
                menuItem.hasSubMenu ? (
                    <li onMouseEnter={e => setIsExpanded(true)} onMouseLeave={e => setIsExpanded(false)}>
                        {menuItem.path ? (
                            <Link to={menuItem.path}>{menuItem.title}&nbsp;<FontAwesomeIcon icon={faAngleDown} /></Link>
                        ) : (
                            <a>{menuItem.title}&nbsp;<FontAwesomeIcon icon={faAngleDown} /></a>
                        )}
                        <ol className={isExpanded ? 'expanded' : ''}>
                            {menuItem.subMenuList.map((item, index) => <DropDownItem key={index} item={item} />)}
                        </ol>
                    </li>
                ) : (
                    <li>
                        <Link to={menuItem.path}>{menuItem.title}</Link>
                    </li>
                )
            }
        </>
    )
};

export default MenuItem;