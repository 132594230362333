import React from 'react';
import './Carousel.scss';
// import Swiper core and required modules
import { Navigation, Pagination, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { locationPath } from '../../helpers/pathHelper';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';

const Carousel = ({ slideList }) => {
    return (
        <div className='carousel'>
            <Swiper
                // install Swiper modules
                modules={[Navigation, Pagination, A11y]}
                spaceBetween={0}
                slidesPerView={1}
                navigation
                pagination={{ clickable: true }}
                loop={true}
                onSwiper={(swiper) => console.log(swiper)}
                onSlideChange={() => console.log('slide change')}>
                {slideList.map((item, index) => <SwiperSlide key={index}>
                    <picture>
                        <source srcSet={`${locationPath}${item.replace('jpg', 'webp')}`} type="image/webp"></source>
                        <img src={`${locationPath}${item}`}></img>
                    </picture>
                </SwiperSlide>)}
            </Swiper>
        </div>
    )
};

Carousel.propTypes = {};

Carousel.defaultProps = {};

export default Carousel;