const MenuList_TC = [
    {
        title: "關於我們",
        hasSubMenu: true,
        subMenuList: [
            { title: "為何選擇我們", hasSubMenu: false, subMenuList: null, path: "/why-us" },
            { title: "我們的工廠", hasSubMenu: false, subMenuList: null, path: "/our-factory" },
            { title: "品質管理", hasSubMenu: false, subMenuList: null, path: "/quality-control" },
            { title: "聯絡我們", hasSubMenu: false, subMenuList: null, path: "/contact-us" }
        ]
    },
    {
        title: "產品",
        hasSubMenu: true,
        path: "/products",
        subMenuList: [
            {
                id: "PP",
                title: "PP",
                hasSubMenu: true,
                path: "/products?category=PP",
                subMenuList: [
                    { title: "押出級", path: "/product-detail?id=PP_Extrusion" },
                    { title: "射出級", path: "/product-detail?id=PP_Injection" },
                    { title: "拉絲級", path: "/product-detail?id=PP_WireDrawing" },
                    { title: "無紡布級", path: "/product-detail?id=PP_NonWovenFabric" }
                ]
            },
            {
                id: "PE",
                title: "PE",
                hasSubMenu: true,
                path: "/products?category=PE",
                subMenuList: [
                    { title: "吹塑級", path: "/product-detail?id=PE_BlowMolding" },
                    { title: "吹膜級", path: "/product-detail?id=PE_BlownFilm" },
                    { title: "押出、擠出級", path: "/product-detail?id=PE_Extrusion" },
                    { title: "透氣母粒", path: "/product-detail?id=PE_MicroporousMasterbatches" }
                ]
            },
            {
                id: "TALC",
                title: "TALC顆粒",
                hasSubMenu: true,
                path: "/products?category=TALC",
                subMenuList: [
                    { title: "PP聚丙烯", path: "/product-detail?id=TALC_PP" },
                    { title: "PE聚乙烯", path: "/product-detail?id=TALC_PE" }
                ]
            },
            {
                id: "Flexibilizer",
                title: "增韌劑",
                hasSubMenu: true,
                path: "/products?category=Flexibilizer",
                subMenuList: [
                    { title: "增韌劑 (馬來酸酐接枝物)", path: "/product-detail?id=Flexibilizer_MaleicAnhydrideGrafted" }
                ]
            },
            {
                id: "Powder",
                title: "粉體銷售",
                hasSubMenu: true,
                path: "/products?category=Powder",
                subMenuList: [
                    { title: "PG1045", path: "/product-detail?id=Powder_PG1045" },
                    // { title: "PG400", path: "/product-detail?id=Powder_PG400" }
                ]
            }
        ]
    },
    {
        title: "客製化",
        hasSubMenu: false,
        subMenuList: null,
        path: "/customize"
    },
    {
        title: "消息",
        hasSubMenu: false,
        subMenuList: null,
        path: "/news"
    }
];

const MenuList_EN = [
    {
        title: "About Us",
        hasSubMenu: true,
        subMenuList: [
            { title: "Why Us", hasSubMenu: false, subMenuList: null, path: "/why-us" },
            { title: "Our Factory", hasSubMenu: false, subMenuList: null, path: "/our-factory" },
            { title: "Quality Control", hasSubMenu: false, subMenuList: null, path: "/quality-control" },
            { title: "Contact Us", hasSubMenu: false, subMenuList: null, path: "/contact-us" }
        ]
    },
    {
        title: "Products",
        hasSubMenu: true,
        path: "/products",
        subMenuList: [
            {
                id: "PP",
                title: "PP",
                hasSubMenu: true,
                path: "/products?category=PP",
                subMenuList: [
                    { title: "Extrusion Grade", path: "/product-detail?id=PP_Extrusion" },
                    { title: "Injection Grade", path: "/product-detail?id=PP_Injection" },
                    { title: "Flat Yarn Grade", path: "/product-detail?id=PP_WireDrawing" },
                    { title: "Multifilament Grade", path: "/product-detail?id=PP_NonWovenFabric" }
                ]
            },
            {
                id: "PE",
                title: "PE",
                hasSubMenu: true,
                path: "/products?category=PE",
                subMenuList: [
                    { title: "Blow Molding Grade", path: "/product-detail?id=PE_BlowMolding" },
                    { title: "Blown Film Grade", path: "/product-detail?id=PE_BlownFilm" },
                    { title: "Extrusion Grade	", path: "/product-detail?id=PE_Extrusion" },
                    { title: "Microporous (Permeability) Grade", path: "/product-detail?id=PE_MicroporousMasterbatches" }
                ]
            },
            {
                id: "TALC",
                title: "TALC",
                hasSubMenu: true,
                path: "/products?category=TALC",
                subMenuList: [
                    { title: "PP TALC", path: "/product-detail?id=TALC_PP" },
                    { title: "PE TALC", path: "/product-detail?id=TALC_PE" }
                ]
            },
            {
                id: "Flexibilizer",
                title: "Toughener",
                hasSubMenu: true,
                path: "/products?category=Flexibilizer",
                subMenuList: [
                    { title: "Toughener (Maleic Anhydride Grafted Polypropylene)", path: "/product-detail?id=Flexibilizer_MaleicAnhydrideGrafted" }
                ]
            },
            {
                id: "Powder",
                title: "Powder",
                hasSubMenu: true,
                path: "/products?category=Powder",
                subMenuList: [
                    { title: "PG1045", path: "/product-detail?id=Powder_PG1045" },
                    // { title: "PG400", path: "/product-detail?id=Powder_PG400" }
                ]
            }
        ]
    },
    {
        title: "Customize",
        hasSubMenu: false,
        subMenuList: null,
        path: "/customize"
    },
    {
        title: "News",
        hasSubMenu: false,
        subMenuList: null,
        path: "/news"
    }
];

const MenuList_VI = [
    {
        title: "Về chúng tôi",
        hasSubMenu: true,
        subMenuList: [
            { title: "Tại sao là chúng tôi", hasSubMenu: false, subMenuList: null, path: "/why-us" },
            { title: "Công ty của chúng tôi", hasSubMenu: false, subMenuList: null, path: "/our-factory" },
            { title: "Chất lượng quản lý", hasSubMenu: false, subMenuList: null, path: "/quality-control" },
            { title: "Liên hệ chúng tôi", hasSubMenu: false, subMenuList: null, path: "/contact-us" }
        ]
    },
    {
        title: "Sản phẩm",
        hasSubMenu: true,
        path: "/products",
        subMenuList: [
            {
                id: "PP",
                title: "PP",
                hasSubMenu: true,
                path: "/products?category=PP",
                subMenuList: [
                    { title: "Extrusion Grade", path: "/product-detail?id=PP_Extrusion" },
                    { title: "Injection Grade", path: "/product-detail?id=PP_Injection" },
                    { title: "Flat Yarn Grade", path: "/product-detail?id=PP_WireDrawing" },
                    { title: "Multifilament Grade", path: "/product-detail?id=PP_NonWovenFabric" }
                ]
            },
            {
                id: "PE",
                title: "PE",
                hasSubMenu: true,
                path: "/products?category=PE",
                subMenuList: [
                    { title: "Blow Molding Grade", path: "/product-detail?id=PE_BlowMolding" },
                    { title: "Blown Film Grade", path: "/product-detail?id=PE_BlownFilm" },
                    { title: "Extrusion Grade	", path: "/product-detail?id=PE_Extrusion" },
                    { title: "Microporous (Permeability) Grade", path: "/product-detail?id=PE_MicroporousMasterbatches" }
                ]
            },
            {
                id: "TALC",
                title: "TALC",
                hasSubMenu: true,
                path: "/products?category=TALC",
                subMenuList: [
                    { title: "PP TALC", path: "/product-detail?id=TALC_PP" },
                    { title: "PE TALC", path: "/product-detail?id=TALC_PE" }
                ]
            },
            {
                id: "Flexibilizer",
                title: "Toughener",
                hasSubMenu: true,
                path: "/products?category=Flexibilizer",
                subMenuList: [
                    { title: "Toughener (Maleic Anhydride Grafted Polypropylene)", path: "/product-detail?id=Flexibilizer_MaleicAnhydrideGrafted" }
                ]
            },
            {
                id: "Powder",
                title: "Powder",
                hasSubMenu: true,
                path: "/products?category=Powder",
                subMenuList: [
                    { title: "PG1045", path: "/product-detail?id=Powder_PG1045" },
                    // { title: "PG400", path: "/product-detail?id=Powder_PG400" }
                ]
            }
        ]
    },
    {
        title: "Tùy chỉnh",
        hasSubMenu: false,
        subMenuList: null,
        path: "/customize"
    },
    {
        title: "Tin tức",
        hasSubMenu: false,
        subMenuList: null,
        path: "/news"
    }
];


export { MenuList_TC, MenuList_EN, MenuList_VI };