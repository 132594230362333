import React from 'react';
import PropTypes from 'prop-types';
import CarouselImageList from '../../config/carouselImageList';
import './Index.scss';
import Carousel from '../../components/Carousel/Carousel';
import Introduce from '../../components/Introduce/Introduce';

const Index = () => (
    <div className='Index'>
        <Carousel slideList={CarouselImageList.index} />
        <Introduce />
    </div>
);

export default Index;