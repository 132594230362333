import { createSlice } from '@reduxjs/toolkit';

let initialDisplayLanguage = sessionStorage.displayLanguage ? JSON.parse(sessionStorage.displayLanguage) : { code: 'zh-tw', displayText: '繁體中文', icon: 'TW' };

export const DisplayLanguageSlice = createSlice({
  name: 'displayLanguage',
  initialState: initialDisplayLanguage,
  reducers: {
    setLanguage: (state, action) => {
      sessionStorage.displayLanguage = JSON.stringify(action.payload)
      
      return action.payload
    }
  },
});

// action creators
export const { setLanguage } = DisplayLanguageSlice.actions;

// reducer
export default DisplayLanguageSlice.reducer;