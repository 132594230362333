import React, { useEffect, useState } from 'react';
import { Link, useSearchParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { useSelector } from 'react-redux';
import Constant from '../../config/constant';
import Loading from '../../components/Loading/Loading';
// import Swiper core and required modules
import { Navigation, Pagination, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import { locationPath } from '../../helpers/pathHelper';
import { MenuList_TC, MenuList_EN, MenuList_VI } from '../../config/menuList';
import i18n from '../../config/i18n';
import './ProductDetail.scss';

const ProductDetail = () => {
    let navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [showLoadingSpinner, setShowLoadingSpinner] = useState(false);
    const [responseData, setResponseData] = useState({
        id: '',
        productImages: [],
        infoLocaleList: []
    });
    const [productDetail, setProductDetail] = useState({
        id: '',
        images: [],
        name: '',
        category: '',
        description: '',
        remark: null,
        detailContentRows: [],
        relativeProducts: []
    });
    const [productCategoryList, setProductCategoryList] = useState(MenuList_TC.find(item => item.path === '/products').subMenuList);
    const displayLanguage = useSelector(state => state.displayLanguage);
    const productId = searchParams.get('id');
    const seeProductDetail = (productId) => { navigate(`/product-detail?id=${productId}`, { replace: false }) };
    const detailHandler = data => {
        let infoLocale = data.infoLocaleList.find(x => x.language === displayLanguage.code);
        let productDetail = {
            id: data.id,
            images: data.productImages,
            name: infoLocale?.name,
            category: infoLocale?.category,
            description: infoLocale?.description,
            remark: infoLocale?.remark,
            detailContentRows: infoLocale?.detailContentRows ? infoLocale.detailContentRows: [],
            relativeProducts: infoLocale?.relativeProducts ? infoLocale.relativeProducts: []
        };

        return productDetail;
    };
    let viewText = i18n[displayLanguage.code].productDetail;

    useEffect(() => {
        let url = `${Constant.apiHost}api/Product/GetProductDetail?productId=${productId}`;
        setShowLoadingSpinner(true);

        axios.get(url)
            .then(response => {
                if (response.data.code === '0000') {
                    setResponseData(response.data.data);
                    setProductDetail(detailHandler(response.data.data));
                }
            })
            .finally(() => setShowLoadingSpinner(false));
    }, [productId]);

    useEffect(() => {
        switch(displayLanguage.code) {
            case 'zh-tw':
                setProductCategoryList(MenuList_TC.find(item => item.path === '/products').subMenuList);

                break;

            case 'en-us':
                setProductCategoryList(MenuList_EN.find(item => item.path === '/products').subMenuList);

                break;

            case 'vi-vn':
                setProductCategoryList(MenuList_VI.find(item => item.path === '/products').subMenuList);
    
                break;
        }

        setProductDetail(detailHandler(responseData));
    }, [displayLanguage]);

    return (
        <div className='ProductDetail'>
            {
                showLoadingSpinner ? <Loading /> : ''
            }
            <div className='main-container'>
                <div className='menu'>
                    <ul>
                        {
                            productCategoryList.map((item, index) => (
                                item.hasSubMenu ? (
                                    <li key={index}>
                                        <a>‧ {item.title}</a>
                                        <ul>
                                            {item.subMenuList.map((item, index) => (<li key={index}>‧ <Link to={item.path}>{item.title}</Link></li>))}
                                        </ul>
                                    </li>
                                ) : (
                                    <li key={index}>‧ <Link to={item.path}>{item.title}</Link></li>
                                )
                            ))
                        }
                    </ul>
                </div>
                <div className='content'>
                    <div className='navigator'>
                        <Link to='/products'>{viewText.navigation.root.text}</Link> / <Link to={`/products?category=${productDetail.category}`}>{productCategoryList.find(x => x.id === productDetail.category)?.title}</Link> / {`${productDetail.name}`}
                    </div>
                    <Swiper
                        // install Swiper modules
                        modules={[Navigation, Pagination, A11y]}
                        spaceBetween={0}
                        slidesPerView={1}
                        navigation
                        pagination={{ clickable: true }}
                        loop={true}>
                        {productDetail.images.map((item, index) => (<SwiperSlide key={index}><img src={`${item.imagePath}`}></img></SwiperSlide>))}
                    </Swiper>
                    <div className='product-description'>
                        <h1>{productDetail.name}</h1>
                        <p>{productDetail.description}</p>
                    </div>
                    <div className='detail-container'>
                        <div className='detail-content'>
                            <table>
                                <thead>
                                    <tr>
                                        <th>{viewText.infoTable.title.item}</th>
                                        <th>{viewText.infoTable.title.measurementMethod}</th>
                                        <th>{viewText.infoTable.title.unit}</th>
                                        <th>{viewText.infoTable.title.value}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        productDetail.detailContentRows.map((row, index) => (
                                            <tr key={index}>
                                                <td>{row.title}</td>
                                                <td>{row.measurementMethod ? row.measurementMethod : '──'}</td>
                                                <td>{row.unit ? row.unit : '──'}</td>
                                                <td>{row.value}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className='contact-us-container'>
                            <div className='contact-us-block'>
                                <h3>{viewText.contactUs.title}</h3>
                                <button>
                                    <Link to='/contact-us'>{viewText.contactUs.button.text}</Link>
                                </button>
                            </div>
                        </div>
                    </div>
                    {(productDetail.relativeProducts.length > 0) ? (
                        <div className='relative-product-container lg'>
                            <h2>{viewText.relativeProduct.title}</h2>
                            <Swiper
                                // install Swiper modules
                                modules={[Navigation, Pagination, A11y]}
                                spaceBetween={25}
                                slidesPerView={4}
                                navigation
                                pagination={{ clickable: true }}
                                loop={false}>
                                {productDetail.relativeProducts.map((item, index) => (
                                    <SwiperSlide key={index}>
                                        <div className='product-container' onClick={seeProductDetail.bind(this, item.id)}>
                                            <img src={`${item.imagePath}`}></img>
                                            <p>{item.name}</p>
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                    ) : ''}
                    {(productDetail.relativeProducts.length > 0) ? (
                        <div className='relative-product-container sm'>
                            <h2>{viewText.relativeProduct.title}</h2>
                            <Swiper
                                // install Swiper modules
                                modules={[Navigation, Pagination, A11y]}
                                spaceBetween={15}
                                slidesPerView={2}
                                navigation
                                pagination={{ clickable: true }}
                                loop={true}>
                                {productDetail.relativeProducts.map((item, index) => (
                                    <SwiperSlide key={index}>
                                        <div className='product-container' onClick={seeProductDetail.bind(this, item.id)}>
                                            <img src={`${item.imagePath}`}></img>
                                            <p>{item.name}</p>
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                    ) : ''}
                </div>
            </div>
        </div>
    );
};

export default ProductDetail;