import { React, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Carousel from '../../components/Carousel/Carousel';
import { locationPath } from '../../helpers/pathHelper';
import CarouselImageList from '../../config/carouselImageList';
import i18n from '../../config/i18n';
import './OurFactory.scss';

const OurFactory = () => {
    const displayLanguage = useSelector(state => state.displayLanguage);
    const [introduceTextStyle, setIntroduceTextStyle] = useState('introduce-block');
    let viewText = i18n[displayLanguage.code].ourFactory;

    useEffect(() => {
        if(displayLanguage.code === 'zh-tw') setIntroduceTextStyle('introduce-block chinese-content');
        else if(displayLanguage.code === 'en-us') setIntroduceTextStyle('introduce-block english-content');
        else setIntroduceTextStyle('introduce-block');
    }, [displayLanguage]);

    return (
        <div className="OurFactory">
            <Carousel slideList={CarouselImageList.ourFactory} />
            <div className='title-block'>
                <h1 data-aos="fade"
                    data-aos-easing="ease-in-out"
                    data-aos-duration="1000">
                    {viewText.title}
                </h1>
            </div>
            <div className='factory-history'>
                <div className='process'>
                    <table>
                        <tbody>
                            {viewText.history.map((item, index) => (
                                <tr key={index}
                                    data-aos="fade-up"
                                    data-aos-easing="ease-in-out"
                                    data-aos-duration="1000">
                                    <td className='year'><div>{item.year}</div></td><td className='dot'>‧‧‧‧‧</td><td className='content'>{item.text}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className={introduceTextStyle}>
                <p data-aos="fade"
                   data-aos-delay="50"
                   data-aos-easing="ease-in-out"
                   data-aos-duration="1000">
                    {viewText.introduce}
                </p>
            </div>
            <div className='factory-introduce'>
                <div className='factory-picture'
                    data-aos="fade"
                    data-aos-easing="ease-in-out"
                    data-aos-duration="1000">
                    <picture>
                        <source srcSet={`${locationPath}/images/our-factory/machine_control.webp`} type="image/webp" />
                        <img src={`${locationPath}/images/our-factory/machine_control.jpg`} alt="圖片_工廠機具操作" />
                    </picture>
                </div>
                <div className='factory-content'
                    data-aos="fade"
                    data-aos-easing="ease-in-out"
                    data-aos-duration="1000">
                    <h3>{viewText.insideFactory.title}</h3>
                    <p>{viewText.insideFactory.text}</p>
                </div>
                <div className='factory-content'
                    data-aos="fade"
                    data-aos-easing="ease-in-out"
                    data-aos-duration="1000">
                    <h3>{viewText.laboratory.title}</h3>
                    <p>{viewText.laboratory.text}</p>
                </div>
                <div className='factory-picture'
                    data-aos="fade"
                    data-aos-easing="ease-in-out"
                    data-aos-duration="1000">
                    <picture>
                        <source srcSet={`${locationPath}/images/our-factory/lab.webp`} type="image/webp" />
                        <img src={`${locationPath}/images/our-factory/lab.jpg`} alt="圖片_實驗室量測" />
                    </picture>
                </div>
            </div>
        </div>
    )
};

export default OurFactory;