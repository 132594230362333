import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from "axios";
import SearchBar from '../SearchBar/SearchBar';
import LanguageSelector from '../LanguageSelector/LanguageSelector';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { Link } from "react-router-dom";
import { locationPath } from '../../helpers/pathHelper';
import MenuItem from '../MenuItem/MenuItem';
import { MenuList_TC, MenuList_EN, MenuList_VI } from '../../config/menuList';
import SideMenu from '../SideMenu/SideMenu';
import Constant from '../../config/constant';
import './Header.scss';

const Header = () => {
  const [showLoadingSpinner, setShowLoadingSpinner] = useState(false);
  const displayLanguage = useSelector(state => state.displayLanguage);
  const [mobileMenuVisibility, setMobileMenuVisibility] = React.useState(false);
  const [menuList, setMenuList] = useState([]);
  const [productMenuList, setProductMenuList] = useState([]);
  const showMobileMenu = () => setMobileMenuVisibility(!mobileMenuVisibility);
  const sideMenuHandler = (show) => { console.log(show); return setMobileMenuVisibility(show) };

  useEffect(() => {
    let url = `${Constant.apiHost}api/Product/GetProductMenuList`;

    setShowLoadingSpinner(true);

    axios.get(url)
      .then(response => {
        if (response.data.code === '0000') {
          let menuList = [];

          setProductMenuList(response.data.data);

          switch (displayLanguage.code) {
            case 'zh-tw':
              MenuList_TC[1].subMenuList = response.data.data.find(x => x.locale === displayLanguage.code).menuItemList;
              MenuList_TC[1].hasSubMenu = MenuList_TC[1].subMenuList.length > 0 ? true : false;
              menuList = MenuList_TC;


              break;

            case 'en-us':
              MenuList_EN[1].subMenuList = response.data.data.find(x => x.locale === displayLanguage.code).menuItemList;
              MenuList_EN[1].hasSubMenu = MenuList_EN[1].subMenuList.length > 0 ? true : false;
              menuList = MenuList_EN;

              break;

            case 'vi-vn':
              MenuList_VI[1].subMenuList = response.data.data.find(x => x.locale === displayLanguage.code).menuItemList;
              MenuList_VI[1].hasSubMenu = MenuList_VI[1].subMenuList.length > 0 ? true : false;
              menuList = MenuList_VI;

              break;
          }
          setMenuList(menuList);
        }
      })
      .finally(() => setShowLoadingSpinner(false));
  }, []);

  useEffect(() => {
    let targetProductMenuList = productMenuList.find(x => x.locale === displayLanguage.code);
    
    if (targetProductMenuList) {
      let menuList = [];

      switch (displayLanguage.code) {
        case 'zh-tw':
          MenuList_TC[1].subMenuList = targetProductMenuList.menuItemList;
          MenuList_TC[1].hasSubMenu = MenuList_TC[1].subMenuList.length > 0 ? true : false;
          menuList = MenuList_TC;

          break;

        case 'en-us':
          MenuList_EN[1].subMenuList = targetProductMenuList.menuItemList;
          MenuList_EN[1].hasSubMenu = MenuList_EN[1].subMenuList.length > 0 ? true : false;
          menuList = MenuList_EN;

          break;

        case 'vi-vn':
          MenuList_VI[1].subMenuList = targetProductMenuList.menuItemList;
          MenuList_VI[1].hasSubMenu = MenuList_VI[1].subMenuList.length > 0 ? true : false;
          menuList = MenuList_VI;

          break;
      }

      setMenuList(menuList);
    }
  }, [displayLanguage]);

  return (
    <div className="header" data-testid="header">
      <div className='header-container'>
        <div className='header-first-row'>
          <div className='header-row-content'>
            <a href='mailto:thuanloiplastic168@gmail.com'><FontAwesomeIcon icon={faEnvelope} /></a>
            <a href='tel:+84-2743728921'><FontAwesomeIcon icon={faPhone} /></a>
            <a><i className='gs-icon icon-facebook' onClick={e => gotoSocialMediaLink('facebook')}></i></a>
          </div>
        </div>
        <div className='header-second-row'>
          <div className='header-row-content'>
            <a className={`header-menu-btn ${mobileMenuVisibility ? 'closed-type' : ''}`} onClick={showMobileMenu}>
              <span></span>
            </a>
            <Link to='/' className="header-logo">
              <img src={`${locationPath}/images/logo.png`} alt="logo"></img>
            </Link>
            <ol className='header-menu-bar'>
              {menuList.map((item, index) => <MenuItem key={index} menuItem={item} />)}
            </ol>
            <SearchBar />
            <div className='header-language-selector'>
              <LanguageSelector />
            </div>
          </div>
        </div>
      </div>
      {mobileMenuVisibility ? (<SideMenu menuList={menuList} handler={sideMenuHandler} />) : ""}
    </div>
  )
};

function gotoSocialMediaLink(type) {
  switch (type) {
    case 'youtube':
      window.open('https://www.youtube.com/', '_blank');

      break;

    case 'facebook':
      window.open('https://www.facebook.com/Thuan-Loi-Plastic-coltd-%E9%A0%86%E5%88%A9%E5%85%AC%E5%8F%B8-110317320715398', '_blank');

      break;

    default:

      break;
  }
}

Header.propTypes = {};

Header.defaultProps = {};

export default Header;
