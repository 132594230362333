import { React, useEffect } from 'react';
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux';
import { locationPath } from '../../helpers/pathHelper';
import i18n from '../../config/i18n';
import './Footer.scss';

const Footer = () => {
    const displayLanguage = useSelector(state => state.displayLanguage);
    let viewText = i18n[displayLanguage.code].footer;

    useEffect(() => {
        viewText = i18n[displayLanguage.code].footer;
    }, [displayLanguage]);

    return (
        <div className='Footer'>
            <div className='footer-logo'>
                <Link to='/'>
                    <img src={`${locationPath}/images/logo.png`} alt="logo"></img>
                </Link>
            </div>
            <div className='company-info'>
                <div><div className='info-title'>- {viewText.address.title}</div> <div className='info-value'>{viewText.address.text}</div></div>
                <div><div className='info-title'>- {viewText.telephone.title}</div> <div className='info-value'>{viewText.telephone.text}</div></div>
                <div><div className='info-title'>- {viewText.email.title}</div> <div className='info-value'>{viewText.email.text}</div></div>
            </div>
        </div>
    )
};

export default Footer;