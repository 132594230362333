import { React, useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMobileScreenButton, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faFacebookF } from '@fortawesome/free-brands-svg-icons';
import Loading from '../../components/Loading/Loading';
import { locationPath } from '../../helpers/pathHelper';
import Constant from '../../config/constant';
import i18n from '../../config/i18n';
import './Introduce.scss';

const Introduce = () => {
    let navigate = useNavigate();
    const displayLanguage = useSelector(state => state.displayLanguage);
    const [showLoadingSpinner, setShowLoadingSpinner] = useState(false);
    const [newsList, setNewsList] = useState([]);
    const [displayNewsList, setDisplayNewsList] = useState([]);
    const gotoNewDetail = newsId => { navigate(`/news-detail?id=${newsId}`, { replace: false }) };
    let viewText = i18n[displayLanguage.code].index;
    const displayNewsHandler = (data) => {
        let result = data.map(x => {
            let target = x.newsLocaleList.find(x => x.locale === displayLanguage.code);
            if (target) {
                return {
                    id: x.id,
                    category: target.category,
                    imagePath: target.imagePath,
                    title: target.title,
                    createTime: target.createTime
                };
            }
        });

        setDisplayNewsList(result);
    }

    useEffect(() => {
        let url = `${Constant.apiHost}api/News/GetTopNewsList?amount=3`;

        setShowLoadingSpinner(true);

        axios.get(url)
            .then(response => {
                if (response.data.code === '0000') {
                    setNewsList(response.data.data);
                    displayNewsHandler(response.data.data);
                }
            })
            .finally(() => setShowLoadingSpinner(false));
    }, []);

    useEffect(() => {
        viewText = i18n[displayLanguage.code].index;

        displayNewsHandler(newsList);
    }, [displayLanguage]);

    return (
        <div className='Introduce'>
            {showLoadingSpinner ? <Loading /> : ''}
            <div className='content-block'>
                <h1 id='company-title'
                    data-aos="fade"
                    data-aos-easing="ease-in-out"
                    data-aos-duration="1000">
                    {viewText.title}
                </h1>
            </div>
            <div className='company-picture'
                data-aos="fade"
                data-aos-easing="ease-in-out"
                data-aos-duration="1000">
                <picture>
                    <source srcSet={`${locationPath}/images/index/introduce/company.webp`} type="image/webp" />
                    <img src={`${locationPath}/images/index/introduce/company.jpg`} />
                </picture>
            </div>
            <div className='introduce-content'
                data-aos="fade"
                data-aos-duration="1000">
                <h2>{viewText.company.title}</h2>
                <p>{viewText.company.text}</p>
                <Link to='/our-factory'>{viewText.readMoreButton}</Link>
            </div>
            <div className='introduce-content'
                data-aos="fade"
                data-aos-easing="ease-in-out"
                data-aos-duration="1000">
                <h2>{viewText.product.title}</h2>
                <p>{viewText.product.text}</p>
                <Link to='/why-us'>{viewText.readMoreButton}</Link>
            </div>
            <div className='company-picture'
                data-aos="fade"
                data-aos-easing="ease-in-out"
                data-aos-duration="1000">
                <picture>
                    <source srcSet={`${locationPath}/images/index/introduce/product.webp`} type="image/webp" />
                    <img src={`${locationPath}/images/index/introduce/product.jpg`}></img>
                </picture>
            </div>
            <div className='company-picture'
                data-aos="fade"
                data-aos-easing="ease-in-out"
                data-aos-duration="1000">
                <picture>
                    <source srcSet={`${locationPath}/images/index/introduce/customize.webp`} type="image/webp" />
                    <img src={`${locationPath}/images/index/introduce/customize.jpg`}></img>
                </picture>
            </div>
            <div className='introduce-content'
                data-aos="fade"
                data-aos-duration="1000">
                <h2>{viewText.customize.title}</h2>
                <p>{viewText.customize.text}</p>
                <Link to='/customize'>{viewText.readMoreButton}</Link>
            </div>
            <div className='content-block'>
                <h2 className='block-title'
                    data-aos="fade"
                    data-aos-easing="ease-in-out"
                    data-aos-duration="1000">
                    {viewText.news.title}
                </h2>
                {displayNewsList.map((item, index) => {
                    if (item) {
                        return (
                            <div className='news-container' key={index}
                                data-aos="fade"
                                data-aos-easing="ease-in-out"
                                data-aos-duration="1000"
                                onClick={gotoNewDetail.bind(this, item.id)}>
                                <div className='image-container'>
                                    {item.imagePath ?
                                        <div className='image-holder' style={{ backgroundImage: `url(${item.imagePath})` }}></div>
                                        : <div className='image-holder' style={{ backgroundImage: `url(${locationPath}images/news/default.jpg)` }}></div>}
                                </div>
                                <div className='news-content'>
                                    <h2>{item.title}</h2>
                                </div>
                            </div>
                        );
                    }
                }
                )}
            </div>
            <h2 className='block-title'
                data-aos="fade"
                data-aos-easing="ease-in-out"
                data-aos-duration="1000">
                {viewText.sales.title}
            </h2>
            <div className='sales-content'
                data-aos="fade"
                data-aos-delay="50"
                data-aos-easing="ease-in-out"
                data-aos-duration="1000">
                <div className='sales-picture' id='sales_lin'>
                </div>
                <div className='sales-name'>
                    <p>{viewText.sales.info[0].name}</p>
                </div>
                <div className='sales-contact'>
                    <a href={`tel:${viewText.sales.info[0].phone}`}><FontAwesomeIcon icon={faMobileScreenButton} /></a>
                    <a href='mailto:thuanloiplastic168@gmail.com'><FontAwesomeIcon icon={faEnvelope} /></a>
                    <a href='https://www.facebook.com/Thuan-Loi-Plastic-coltd-%E9%A0%86%E5%88%A9%E5%85%AC%E5%8F%B8-110317320715398' target='_blank'><FontAwesomeIcon icon={faFacebookF} /></a>
                </div>
            </div>
            <div className='sales-content'
                data-aos="fade"
                data-aos-delay="50"
                data-aos-easing="ease-in-out"
                data-aos-duration="1000">
                <div className='sales-picture' id='sales_cao'>
                </div>
                <div className='sales-name'>
                    <p>{viewText.sales.info[1].name}</p>
                </div>
                <div className='sales-contact'>
                    <a href={`tel:${viewText.sales.info[1].phone}`}><FontAwesomeIcon icon={faMobileScreenButton} /></a>
                    <a href='mailto:thuanloiplastic168@gmail.com'><FontAwesomeIcon icon={faEnvelope} /></a>
                    <a href='https://www.facebook.com/Thuan-Loi-Plastic-coltd-%E9%A0%86%E5%88%A9%E5%85%AC%E5%8F%B8-110317320715398' target='_blank'><FontAwesomeIcon icon={faFacebookF} /></a>
                </div>
            </div>
            <div className='sales-content'
                data-aos="fade"
                data-aos-delay="50"
                data-aos-easing="ease-in-out"
                data-aos-duration="1000">
                <div className='sales-picture' id='sales_ron'>
                </div>
                <div className='sales-name'>
                    <p>{viewText.sales.info[2].name}</p>
                </div>
                <div className='sales-contact'>
                    <a href={`tel:${viewText.sales.info[2].phone}`}><FontAwesomeIcon icon={faMobileScreenButton} /></a>
                    <a href='mailto:thuanloiplastic168@gmail.com'><FontAwesomeIcon icon={faEnvelope} /></a>
                    <a href='https://www.facebook.com/Thuan-Loi-Plastic-coltd-%E9%A0%86%E5%88%A9%E5%85%AC%E5%8F%B8-110317320715398' target='_blank'><FontAwesomeIcon icon={faFacebookF} /></a>
                </div>
            </div>
        </div>
    )
};

export default Introduce;