import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { locationPath } from '../../helpers/pathHelper';
import i18n from '../../config/i18n';
import './WhyUs.scss';

const WhyUs = () => {
    const displayLanguage = useSelector(state => state.displayLanguage);
    let viewText = i18n[displayLanguage.code].whyUs;

    useEffect(() => {
        viewText = i18n[displayLanguage.code].whyUs;
    }, [displayLanguage]);

    return (
        <div className='WhyUs'>
            <div className='image-block'>
                <div className='image-holder' style={{ backgroundImage: `url(${locationPath}images/why-us/business.jpg)` }}></div>
            </div>
            <div className='title-block'>
                <h1 data-aos="fade"
                    data-aos-easing="ease-in-out"
                    data-aos-duration="1000">
                    {viewText.title}
                </h1>
            </div>
            <div className='why-us-content'>
                <div className='why-us-picture'
                     data-aos="fade"
                     data-aos-easing="ease-in-out"
                     data-aos-duration="1000">
                    <picture>
                        <source srcSet={`${locationPath}/images/why-us/products.webp`} type="image/webp" />
                        <img src={`${locationPath}/images/why-us/products.jpg`} />
                    </picture>
                </div>
                <div className='why-us-description'
                    data-aos="fade"
                    data-aos-easing="ease-in-out"
                    data-aos-duration="1000">
                    <h2>{viewText.product.title}</h2>
                    <p>{viewText.product.text}</p>
                </div>
                <div className='why-us-description'
                    data-aos="fade"
                    data-aos-easing="ease-in-out"
                    data-aos-duration="1000">
                    <h2>{viewText.factory.title}</h2>
                    <p>{viewText.factory.text}</p>
                </div>
                <div className='why-us-picture'
                    data-aos="fade"
                    data-aos-easing="ease-in-out"
                    data-aos-duration="1000">
                    <picture>
                        <source srcSet={`${locationPath}/images/why-us/factory_inside.webp`} type="image/webp" />
                        <img src={`${locationPath}/images/why-us/factory_inside.jpg`} />
                    </picture>
                </div>
                <div className='product-overview'>
                    <h2 className='block-title'
                        data-aos="fade"
                        data-aos-easing="ease-in-out"
                        data-aos-duration="1000">
                        {viewText.quality.title}
                    </h2>
                    <div className='product-card'
                         data-aos="fade"
                         data-aos-delay="50"
                         data-aos-easing="ease-in-out"
                         data-aos-duration="1000">
                        <picture>
                            <source srcSet={`${locationPath}/images/why-us/product_PP.webp`} type="image/webp" />
                            <img src={`${locationPath}/images/why-us/product_PP.jpg`}></img>
                        </picture>
                        <div className='cover'>{viewText.quality.textList[0]}</div>
                    </div>
                    <div className='product-card'
                         data-aos="fade"
                         data-aos-delay="50"
                         data-aos-easing="ease-in-out"
                         data-aos-duration="1000">
                        <picture>
                            <source srcSet={`${locationPath}/images/why-us/product_PE.webp`} type="image/webp" />
                            <img src={`${locationPath}/images/why-us/product_PE.jpg`}></img>
                        </picture>
                        <div className='cover'>{viewText.quality.textList[1]}</div>
                    </div>
                    <div className='product-card'
                         data-aos="fade"
                         data-aos-delay="50"
                         data-aos-easing="ease-in-out"
                         data-aos-duration="1000">
                        <picture>
                            <source srcSet={`${locationPath}/images/why-us/product_other.webp`} type="image/webp" />
                            <img src={`${locationPath}/images/why-us/product_other.jpg`}></img>
                        </picture>
                        <div className='cover'>{viewText.quality.textList[2]}</div>
                    </div>
                </div>
                <h2 className='block-title'
                    data-aos="fade"
                    data-aos-easing="ease-in-out"
                    data-aos-duration="1000">
                    {viewText.safety.title}
                </h2>
                <div className='technique-overview'>
                    <div className='technique-introduction'
                         data-aos="fade"
                         data-aos-easing="ease-in-out"
                         data-aos-duration="1000">
                        <picture>
                            <source srcSet={`${locationPath}/images/why-us/operating_forklift.webp`} type="image/webp" />
                            <img src={`${locationPath}/images/why-us/operating_forklift.jpg`}></img>
                        </picture>
                        <p>{viewText.safety.textList[0]}</p>
                    </div>
                    <div className='technique-introduction'
                         data-aos="fade"
                         data-aos-easing="ease-in-out"
                         data-aos-duration="1000">
                        <picture>
                            <source srcSet={`${locationPath}/images/why-us/employee.webp`} type="image/webp" />
                            <img src={`${locationPath}/images/why-us/employee.jpg`}></img>
                        </picture>
                        <p>{viewText.safety.textList[1]}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WhyUs;