import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faXmarkCircle, faCircleCheck, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
import './MessageModal.scss'

const MessageModal = ({ message, handler }) => {
    const closeModal = (show) => handler(show);
    let title = { type: 'alert', icon: faTriangleExclamation, text: '提醒' };

    switch (message.type) {
        case 'error':
            title = { type: 'error', icon: faXmarkCircle, text: '錯誤' };

            break;

        case 'alert':
            title = { type: 'alert', icon: faTriangleExclamation, text: '提醒' };

            break;

        case 'success':
            title = { type: 'success', icon: faCircleCheck, text: '訊息' };

            break;
    }

    return (
        <div className='MessageModal'>
            <div className='modal'>
                <div className='toolbar'>
                    <FontAwesomeIcon icon={faXmark} onClick={closeModal.bind(this, false)} />
                </div>
                <div className='title'>
                    <h3 className={title.type}><FontAwesomeIcon icon={title.icon} /> {title.text}</h3>
                </div>
                <div className='message'>
                    <p>{message.message}</p>
                </div>
            </div>
        </div>
    );
};

export default MessageModal;