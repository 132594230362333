import React from 'react';
import LanguageSelector from '../LanguageSelector/LanguageSelector';
import './SideMenu.scss';
import SideMenuItem from './SideMenuItem';

const SideMenu = ({ menuList, handler }) => {
    const closeMenu = (e) => {
        if(e.target.className === 'SideMenu') handler(false);
    };

    return (
        <div className='SideMenu' onClick={closeMenu}>
            <div className='menu-container'>
                <ol>
                    {menuList.map((item, index) => <SideMenuItem key={index} item={item} handler={handler} />)}
                </ol>
                <div className='language-selector'>
                    <LanguageSelector />
                </div>
            </div>
        </div>
    )
};

export default SideMenu;