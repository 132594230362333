import React from 'react';
import ReactDOM from 'react-dom/client';
import { HashRouter, Routes, Route } from "react-router-dom";
import store from './store/Config'
import { Provider } from 'react-redux'
import reportWebVitals from './reportWebVitals';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Layout from './pages/Layout/Layout';
import Index from './pages/Index/Index'
import Products from './pages/Products/Products';
import ContactUs from './pages/ContactUs/ContactUs';
import WhyUs from './pages/WhyUs/WhyUs';
import OurFactory from './pages/OurFactory/OurFactory';
import QualityControl from './pages/QualityControl/QualityControl';
import News from './pages/News/News';
import ProductDetail from './pages/ProductDetail/ProductDetail';
import Customize from './pages/Customize/Customize';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import NewsDetail from './pages/NewsDetail/NewsDetail';
import SearchResult from './pages/SearchResult/SearchResult';
import './index.scss';

const root = ReactDOM.createRoot(document.getElementById('root'));
document.querySelector("meta[name='theme-color']").setAttribute("content", "#509c44");

AOS.init({
  once: true
});

root.render(
  <Provider store={store}>
    <HashRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Index />} />
          <Route path="why-us" element={<WhyUs />} />
          <Route path="our-factory" element={<OurFactory />} />
          <Route path="quality-control" element={<QualityControl />} />
          <Route path="contact-us" element={<ContactUs />} />
          <Route path="products" element={<Products />} />
          <Route path="product-detail" element={<ProductDetail />} />
          <Route path="customize" element={<Customize />} />
          <Route path="news" element={<News />} />
          <Route path="news-detail" element={<NewsDetail />} />
          <Route path="search-result" element={<SearchResult />} />
        </Route>
      </Routes>
    </HashRouter>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
