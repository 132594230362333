import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import './SideMenuItem.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import SideMenuSubItem from './SideMenuSubItem';

const SideMenuItem = ({ item, handler }) => {
    const navigate = useNavigate()
    const [isExpanded, setIsExpanded] = useState(false);

    return (
        item.hasSubMenu ?
            (
                <li className='sidememu-item'>
                    <a onClick={e => setIsExpanded(!isExpanded)} className={isExpanded ? 'expanded' : ''}>
                        {item.title}&nbsp;<FontAwesomeIcon icon={faAngleDown} />
                    </a>
                    <ol className={isExpanded ? 'expanded' : ''}>
                        {item.subMenuList.map((item, index) => <SideMenuItem key={index} item={item} handler={handler} />)}
                    </ol>
                </li>
            ) :
            (
                <li className='sidememu-item'><a onClick={() => { navigate(item.path); return handler(false) }}>{item.title}</a></li>
            )
    )
};

export default SideMenuItem;