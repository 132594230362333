import React, { useEffect, useState } from 'react';
import axios from "axios";
import { useSelector } from 'react-redux';
import Carousel from '../../components/Carousel/Carousel';
import Loading from '../../components/Loading/Loading';
import MessageModal from '../../components/MessageModal/MessageModal';
import CarouselImageList from '../../config/carouselImageList';
import Constant from '../../config/constant';
import i18n from '../../config/i18n';
import './ContactUs.scss';

const ContactUs = () => {
    const [showLoadingSpinner, setShowLoadingSpinner] = useState(false);
    const [showAlertModal, setShowAlertModal] = useState(false);
    const [countryList, setCountryList] = useState([{ countryCode: '', countryName: '請選擇', countryNameEn: 'Please Select' }]);
    const [alertMessage, setAlertMessage] = useState({ type: '', message: '' });
    const [chineseCountryName, setChineseCountryName] = useState(true);
    const displayLanguage = useSelector(state => state.displayLanguage);
    const modalHandler = (show) => setShowAlertModal(show);
    const sendMessage = (e) => {
        setShowLoadingSpinner(true);
        e.preventDefault();

        let url = `${Constant.apiHost}api/UserMessage/SaveMessage`;
        let check = false;
        let requestData = {
            name: document.querySelector('.contact-us-form input[name="name"]').value,
            email: document.querySelector('.contact-us-form input[name="email"]').value,
            phone: document.querySelector('.contact-us-form input[name="phone"]').value,
            country: document.querySelector('.contact-us-form select[name="country"]').value,
            message: document.querySelector('.contact-us-form textarea[name="message"]').value,
        };

        if (!requestData.name) {
            setAlertMessage({ type: 'alert', message: '請填寫「名字」欄位！' });
        } else if (!requestData.email) {
            setAlertMessage({ type: 'alert', message: '請填寫「E-mail」欄位！' });
        } else if (!requestData.phone) {
            setAlertMessage({ type: 'alert', message: '請填寫「連絡電話」欄位！' });
        } else if (!requestData.message) {
            setAlertMessage({ type: 'alert', message: '請填寫「訊息內容」！' });
        } else if (!requestData.email.match(/^([\w\.\-]+)@([\w\-]+)((\.(\w){2,3})+)$/g)) {
            setAlertMessage({ type: 'alert', message: '請輸入正確的 E-mail 格式！' });
        } else {
            check = true;
        }

        if (!check) {
            setShowAlertModal(true);
            setShowLoadingSpinner(false);
        } else {
            axios.post(url, requestData)
                .then(response => {
                    if (response.data.code === '0000') {
                        setAlertMessage({ type: 'success', message: '訊息送出完成！' });
                        setShowAlertModal(true);

                        document.querySelector('.contact-us-form input[name="name"]').value = '';
                        document.querySelector('.contact-us-form input[name="email"]').value = '';
                        document.querySelector('.contact-us-form input[name="phone"]').value = '';
                        document.querySelector('.contact-us-form select[name="country"]').value = '';
                        document.querySelector('.contact-us-form textarea[name="message"]').value = '';
                    } else {
                        setAlertMessage({ type: 'error', message: '送出訊息時發生錯誤，請稍後再試...' });
                        setShowAlertModal(true);
                    }
                })
                .catch(error => {
                    console.error(error.message);

                    setAlertMessage({ type: 'error', message: '送出訊息時發生錯誤，請稍後再試...' });
                    setShowAlertModal(true);
                })
                .finally(() => setShowLoadingSpinner(false));
        }
    };
    let viewText = i18n[displayLanguage.code].contactUs;

    useEffect(() => {
        let url = `${Constant.apiHost}api/Common/GetCountryList`;

        setShowLoadingSpinner(true);

        axios.get(url)
            .then(response => {
                if (response.data.code === '0000') setCountryList(countryList.concat(response.data.data));
            })
            .finally(() => setShowLoadingSpinner(false));
    }, []);

    useEffect(() => {
        viewText = i18n[displayLanguage.code].contactUs;

        if (displayLanguage.code === 'zh-tw') setChineseCountryName(true);
        else setChineseCountryName(false);
    }, [displayLanguage]);

    return (
        <div className='ContactUs'>
            {
                showLoadingSpinner ? <Loading /> : ''
            }
            {
                showAlertModal ? <MessageModal message={alertMessage} handler={modalHandler} /> : ''
            }
            <Carousel slideList={CarouselImageList.contactUs} />
            <form className='contact-us-form' onSubmit={sendMessage}>
                <h3>{viewText.title}</h3>
                <div className='form-row'>
                    <label className='mark'>{viewText.formColumns.name.title}</label>
                    <input type="text" name='name'></input>
                </div>
                <div className='form-row'>
                    <label className='mark'>{viewText.formColumns.email.title}</label>
                    <input type="email" name='email'></input>
                </div>
                <div className='form-row'>
                    <label className='mark'>{viewText.formColumns.phone.title}</label>
                    <input type="tel" name='phone'></input>
                </div>
                <div className='form-row'>
                    <label>{viewText.formColumns.area.title}</label>
                    {
                        chineseCountryName ? (
                            <select name='country'>
                                {countryList.map((item, index) => (
                                    <option key={index} value={item.countryCode}>{item.countryName}</option>
                                ))}
                            </select>
                        ) : (
                            <select name='country'>
                                {countryList.map((item, index) => (
                                    <option key={index} value={item.countryCode}>{item.countryNameEn}</option>
                                ))}
                            </select>
                        )
                    }
                </div>
                <div className='form-row'>
                    <label className='mark'>{viewText.formColumns.message.title}</label>
                    <textarea name='message'></textarea>
                </div>
                <div className='form-row button-block'>
                    <button type='submit'>{viewText.buttons.send.text}</button>
                </div>
            </form>
        </div>
    );
};

export default ContactUs;