import React, { useState, useEffect } from 'react';
import { useSearchParams, useNavigate, Link } from "react-router-dom";
import { useSelector } from 'react-redux';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import axios from "axios";
import Moment from 'moment';
import Constant from '../../config/constant';
import Loading from '../../components/Loading/Loading';
import i18n from '../../config/i18n';
import { locationPath } from '../../helpers/pathHelper';
import './SearchResult.scss';
import 'react-tabs/style/react-tabs.scss';

const SearchResult = () => {
    let navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [showLoadingSpinner, setShowLoadingSpinner] = useState(false);
    const [productSearchResult, setProductSearchResult] = useState([]);
    const [productList, setProductList] = useState([]);
    const [newsSearchResult, setNewsSearchResult,] = useState([]);
    const [newsList, setNewsList] = useState([]);
    const keyword = searchParams.get('keyword');
    const displayLanguage = useSelector(state => state.displayLanguage);
    let viewText = i18n[displayLanguage.code].searchResult;
    const seeProductDetail = (productId) => { navigate(`/product-detail?id=${productId}`, { replace: false }) };
    const productListHandler = (data) => data.map((item) => {
        let localeInfo = item.nameLocaleList.find(x => x.locale === displayLanguage.code);
        if (localeInfo) {
            return {
                id: item.id,
                imagePath: item.imagePath,
                name: localeInfo.name
            };
        }
    });
    const displayNewsHandler = (data) => {
        let result = data.map(x => {
            let target = x.newsLocaleList.find(x => x.locale === displayLanguage.code);
            if (target) {
                return {
                    id: x.id,
                    category: target.category,
                    imagePath: target.imagePath,
                    title: target.title,
                    createTime: target.createTime
                };
            }
        });

        setNewsList(result);
    }

    useEffect(() => {
        /* 查詢產品 */
        let url = `${Constant.apiHost}api/Product/SearchProduct?keyword=${keyword}`;

        setShowLoadingSpinner(true);

        axios.get(url)
            .then(response => {
                if (response.data.code === '0000') {
                    setProductSearchResult(response.data.data);
                    setProductList(productListHandler(response.data.data));
                } else {
                    setProductSearchResult([]);
                    setProductList([]);
                }
            })
            .finally(() => setShowLoadingSpinner(false));

        /* 查詢最新消息 */
        url = `${Constant.apiHost}api/News/SearchNews?keyword=${keyword}&locale=${displayLanguage.code}`;

        setShowLoadingSpinner(true);

        axios.get(url)
            .then(response => {
                if (response.data.code === '0000') {
                    setNewsSearchResult(response.data.data);
                    displayNewsHandler(response.data.data);
                }
            })
            .finally(() => setShowLoadingSpinner(false));
    }, [keyword]);

    useEffect(() => {
        viewText = i18n[displayLanguage.code].searchResult;

        setProductList(productListHandler(productSearchResult));
        displayNewsHandler(newsSearchResult);
    }, [displayLanguage]);

    return (
        <div className="SearchResult">
            {showLoadingSpinner ? <Loading /> : ''}
            <div className="title">
                <h1>{viewText.title}</h1>
            </div>
            <Tabs>
                <TabList>
                    <Tab>{viewText.tabs[0].title}</Tab>
                    <Tab>{viewText.tabs[1].title}</Tab>
                </TabList>

                <TabPanel className="product-tab-panel">
                    {
                        productList.map(item => {
                            if (item) {
                                return (
                                    <div className='product' key={item.id} onClick={seeProductDetail.bind(this, item.id)}>
                                        <div className='image-container'>
                                            <img src={`${item.imagePath}`}></img>
                                        </div>
                                        <p>{item.name}</p>
                                    </div>
                                )
                            }
                        })
                    }
                </TabPanel>
                <TabPanel className="news-tab-panel">
                    {
                        newsList.map((item, index) => {
                            if (item) {
                                return (
                                    <div className='news-card' key={index}>
                                        <div className='news-image-container'>
                                            {
                                                item.imagePath ?
                                                    <div className='image-holder' style={{ backgroundImage: `url(${item.imagePath})` }}></div>
                                                    : <div className='image-holder' style={{ backgroundImage: `url(${locationPath}images/news/default.jpg)` }}></div>
                                            }
                                        </div>
                                        <div className='news-content-container'>
                                            <div>
                                                <div className='news-category'>{item.category}</div>
                                                <div className='news-release-date'>{Moment(item.createTime).format('YYYY-MM-DD')}</div>
                                            </div>
                                            <h2>{item.title}</h2>
                                            <b></b>
                                            <Link to={`/news-detail?id=${item.id}`}>{viewText.tabs[1].buttons.readMore.text}</Link>
                                        </div>
                                    </div>
                                )
                            }
                        })
                    }
                </TabPanel>
            </Tabs>
        </div>
    );
}

export default SearchResult;