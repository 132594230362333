const carouselImageList = {
    index: [
        "/images/index/front_gate.jpg",
        "/images/index/factory_1.jpg",
        // "/images/index/factory_2.jpg",
        "/images/index/finished_products.jpg"
    ],
    ourFactory: [
        "/images/our-factory/factory_outside.jpg",
        "/images/our-factory/factory.jpg",
    ],
    contactUs: [
        "/images/contact-us/company.jpg",
        "/images/contact-us/shipment.jpg",
        "/images/contact-us/business.jpg"
    ],
    customize: [
        "/images/customize/image_01.jpg",
        "/images/customize/image_02.jpg",
        "/images/customize/image_03.jpg"
    ]
};

export default carouselImageList;