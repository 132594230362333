import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { locationPath } from '../../helpers/pathHelper';
import i18n from '../../config/i18n';
import './QualityControl.scss';

const QualityControl = () => {
    const displayLanguage = useSelector(state => state.displayLanguage);
    let viewText = i18n[displayLanguage.code].qualityControl;

    useEffect(() => { }, [displayLanguage]);

    return (
        <div className='QualityControl'>
            <div className='qc-title'>
                <h1>{viewText.title}</h1>
            </div>
            <div className='qc-content-container'>
                <div className='content-block'
                     data-aos="fade"
                     data-aos-easing="ease-in-out"
                     data-aos-duration="1000">
                    <picture>
                        <source srcSet={`${locationPath}/images/quality-control/lab.webp`} type="image/webp" />
                        <img src={`${locationPath}/images/quality-control/lab.jpg`} />
                    </picture>
                    <p>{viewText.contentList[0]}</p>
                </div>
                <div className='content-block'
                     data-aos="fade"
                     data-aos-easing="ease-in-out"
                     data-aos-duration="1000">
                    <picture>
                        <source srcSet={`${locationPath}/images/quality-control/automated_production.webp`} type="image/webp" />
                        <img src={`${locationPath}/images/quality-control/automated_production.jpg`} />
                    </picture>
                    <p>{viewText.contentList[1]}</p>
                </div>
                <div className='content-block'
                     data-aos="fade"
                     data-aos-easing="ease-in-out"
                     data-aos-duration="1000">
                    <picture>
                        <source srcSet={`${locationPath}/images/quality-control/examination.webp`} type="image/webp" />
                        <img src={`${locationPath}/images/quality-control/examination.jpg`} />
                    </picture>
                    <p>{viewText.contentList[2]}</p>
                </div>
                <div className='content-block'
                     data-aos="fade"
                     data-aos-easing="ease-in-out"
                     data-aos-duration="1000">
                    <picture>
                        <source srcSet={`${locationPath}/images/quality-control/product.webp`} type="image/webp" />
                        <img src={`${locationPath}/images/quality-control/product.jpg`} />
                    </picture>
                    <p>{viewText.contentList[3]}</p>
                </div>
            </div>
        </div>
    );
};

export default QualityControl;