import React from 'react';
import Flag from 'react-flagpack'
import './LanguageSelectList.scss';

const LanguageSelectList = ({ handler }) => {
    const languageList = [
        { code: 'zh-tw', displayText: '繁體中文', icon: 'TW' },
        { code: 'en-us', displayText: 'English', icon: 'US' },
        { code: 'vi-vn', displayText: 'Tiếng Việt', icon: 'VN' }
    ];

    return (
        <div className='languageSelectList'>
            {
                languageList.map((item, index) => (
                    <div className='select-item' key={index} onClick={() => handler(languageList[index])}>
                        <Flag code={item.icon} size="S" />
                        <div className='language-name'>{item.displayText}</div>
                    </div>
                ))
            }
        </div>
    )
};

LanguageSelectList.propTypes = {};

LanguageSelectList.defaultProps = {};

export default LanguageSelectList;