import React, { useState } from 'react';
import { Link } from "react-router-dom";
import './DropDownItem.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'

const DropDownItem = ({ item }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    return (
        <>
            {item.hasSubMenu ? (
                <li onMouseEnter={e => setIsExpanded(true)} onMouseLeave={e => setIsExpanded(false)}>
                    <a>{item.title}&nbsp;<FontAwesomeIcon icon={faAngleRight} /></a>
                    <ol className={`menu-layer-2 ${isExpanded ? 'expanded' : ''}`}>
                        {item.subMenuList.map((item, index) => (<li key={index}><Link to={item.path}>{item.title}</Link></li>))}
                    </ol>
                </li>
            ) : (
                <li>
                    <Link to={item.path}>{item.title}</Link>
                </li>
            )}
        </>
    )
};

export default DropDownItem;