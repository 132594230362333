const i18n = {
    /* 繁體中文 */
    'zh-tw': {
        /* Header */
        header: {},
        /* Footer */
        footer: {
            address: { title: '地址：', text: '越南平陽省宜安市新東協Ｂ工業區2路35號' },
            telephone: { title: '電話：', text: '84-2743728921' },
            email: { title: 'E-mail：', text: 'thuanloiplastic168@gmail.com' }
        },
        /* 首頁 */
        index: {
            title: '順 利 塑 膠',
            company: { title: '公司簡介', text: '生產基地位於越南 平陽省 新東協工業區,四通八達的交通樞紐,完善的運輸團隊,離外銷港口也僅20公里' },
            product: { title: '產品簡介', text: '碳酸鈣與滑石粉作為一種天然的粉體產品， 以無毒性、白度高被廣泛應用於各行業中，在塑膠產品的應用上能減少製品的收縮率、提高尺寸的穩定性、增強其硬度和剛性、有效的改變塑膠的流變性能和降低生產成本。' },
            customize: { title: '產品客製化', text: '我司優秀的業務團隊，可以為客戶提供專業的產品解說及服務，幫助客戶在產品使用上達到所需之標準。工廠備有完整獨立實驗室，可依照客戶需求數據化產品物性及為客戶提供客製化商品等服務。在生產過程中採用高壓密鍊機進行混鍊，使原料更均勻分散的混合在一起，並使用可替換式積木雙螺桿，應對各項產品所需的生產要求。' },
            news: { title: '最 新 消 息' },
            sales: {
                title: '業 務 介 紹', info: [
                    { name: '林榆祥', phone: '+886-919560429', email: 'thuanloiplastic168@gmail.com' },
                    { name: '曹明義', phone: '+886-952791166', email: 'thuanloiplastic168@gmail.com' },
                    { name: 'Tran Thanh Vinh', phone: '+84-913679977', email: 'thuanloiplastic168@gmail.com' }
                ]
            },
            readMoreButton: '查看詳情'
        },
        /* 我們的工廠 */
        ourFactory: {
            title: '我 們 的 工 廠',
            introduce: '台灣順利責任有限公司於1995年設立越南生產基地，專業生產、銷售 PP、PE碳酸鈣填充母粒、TALC滑石粉母粒、塑膠增韌劑與各類型客製化工程塑膠等服務項目，順利公司從原料來源、製品管控、品質要求都制定嚴格的流程，以維護消費者使用順利公司產品的保障。20多年來順利公司秉持著永續經營、誠信服務的初衷，獲得各國客戶的信任及支持讓順利公司產品能推廣於五大洲，順利公司未來將持續秉持專業、創造、積極服務客戶創造雙贏。',
            history: [
                { year: '1995', text: '設立 越南 順利責任有限公司' },
                { year: '1998', text: '投入生產製造各類型碳酸鈣母粒銷售' },
                { year: '2003', text: '增設裁斷板生產及銷售部門' },
                { year: '2006', text: '在平陽省宜安市新東協B工業區 購置土地興建廠' },
                { year: '2007', text: '將公司及工廠 移往新東協B工業區生產' },
                { year: '2009', text: '陸續機械更換高壓密鍊機 雙螺桿生產' },
                { year: '2014', text: '建立廠區實驗室' },
                { year: '2015', text: '與台灣國立大學簽署產經合作' },
                { year: '2017', text: '購買廠區後方土地進行增建增加生產線' },
                { year: '2019', text: '實驗室增設各類型測試機' },
                { year: '2022', text: '增設粉體銷售事業部' }
            ],
            insideFactory: { title: '內部介紹', text: '生產廠區內有六條專業級生產線並增設粉末回槽處理來優化廠內環境、動線及消防符合法令規範並遵守ISO各流程規劃，獲得本地各大企業認證指定用料。' },
            laboratory: { title: '實驗室量測簡介', text: '獨立實驗開發室，從原料入廠到出廠皆由實驗室內使用各項指標機台檢測，室內並有各類小型塑膠加工成型機，更準確能依據所有數值來改善產品更為精化而客製產品也能完美達到客戶所需物性。' }
        },
        /* 為什選擇我們 */
        whyUs: {
            title: '為 何 選 擇 我 們 ？',
            product: { title: '產品簡介', text: '各級原物料進廠皆接受品管單位審驗查核各物性，確認無誤才可入備料倉庫，待取料生產。' },
            factory: { title: '工廠簡介', text: '我司生產採用高速密鍊機，可變換式雙螺桿，生產品質穩定有效率，對於客戶訂製品能更有效的調整客戶所需要的物性來生產。' },
            quality: {
                title: '品質原則', textList: [
                    '原產地粉體，品質穩定',
                    '各大原料廠正料為生產使用基材',
                    '添加環保助劑，使用安心'
                ]
            },
            safety: {
                title: '廠區安全',
                textList: [
                    '各區域操作人員皆有合格證書',
                    '備配完整制服、安全鞋及生產安全工具'
                ]
            }
        },
        /* 品質管理 */
        qualityControl: {
            title: '品 質 管 理',
            contentList: [
                '備有各類型檢驗設備及完整實驗室，進場物料皆需通過檢驗，從原料開始把關。',
                '半自動化生產，品質穩定。',
                '按照SOP檢測，達成物性數據。',
                '通過以上品質管理，得到令人信賴的「順利」產品！'
            ]
        },
        /* 聯絡我們 */
        contactUs: {
            title: '留下訊息給我們',
            formColumns: {
                name: { title: '名字' },
                email: { title: 'E-mail：' },
                phone: { title: '連絡電話：' },
                area: { title: '區域：' },
                message: { title: '訊息內容：' },
            },
            buttons: {
                send: { text: '送 出' }
            }
        },
        /* 產品 */
        productList: {
            total: {
                prefix: '總共',
                suffix: '筆結果'
            },
            filterButton: { text: '篩選' }
        },
        /* 產品詳細 */
        productDetail: {
            navigation: {
                root: { text: '產品' }
            },
            infoTable: {
                title: {
                    item: '項目',
                    measurementMethod: '測試方式',
                    unit: '單位',
                    value: '數值'
                }
            },
            contactUs: {
                title: '有興趣嗎？',
                button: { text: '聯絡我們' }
            },
            relativeProduct: {
                title: '相關產品'
            }
        },
        /* 最新消息 */
        news: {
            title: '最 新 消 息',
            buttons: {
                readMore: { text: '閱讀更多' }
            }
        },
        /* 消息內容 */
        newsDetail: {
            navigation: {
                root: { text: '消息列表' },
                child: { text: '消息內容' }
            },
            buttons: {
                back: { text: '返回消息列表' }
            }
        },
        /* 客製化 */
        customize: {
            formColumns: {
                company: { title: '公司名稱：' },
                username: { title: '聯絡人姓名' },
                email: { title: 'E-mail：' },
                productCategory: {
                    title: '客製化類別：',
                    items: {
                        PP: { text: 'PP' },
                        PE: { text: 'PE' },
                        other: { text: '其他' }
                    }
                },
                productLevel: { title: '產品成型方式(射出、押出等)：' },
                hasExperience: {
                    title: '是否有使用類似之產品：',
                    items: {
                        true: { text: '是' },
                        false: { text: '否' }
                    }
                },
                country: {
                    title: '國家：',
                    items: {
                        taiwan: { text: '臺灣' },
                        vietnam: { text: '越南' },
                        other: { text: '其他' }
                    }
                },
                remark: { title: '備註：' },
                message: { title: '內容描述：' }
            },
            buttons: {
                send: { text: '送 出' }
            }
        },
        /* 全站搜尋 */
        searchResult: {
            title: '搜尋結果',
            tabs: [
                { title: '產品' },
                {
                    title: '最新消息',
                    buttons: {
                        readMore: { text: '閱讀更多' }
                    }
                }
            ]
        }
    },
    /* 越南文 */
    'vi-vn': {
        footer: {
            address: { title: 'Địa chỉ: ', text: 'Lô B2-35 KCN Tân Đông Hiệp B, phường Tân Đông Hiệp, TP. Dĩ An, tỉnh Bình Dương' },
            telephone: { title: 'Số điện thoại: ', text: '84-2743728921' },
            email: { title: 'E-mail: ', text: 'thuanloiplastic168@gmail.com' }
        },
        index: {
            title: 'CÔNG TY TNHH Thuận Lợi',
            company: { title: 'Giới thiệu cty', text: 'Nhà máy sản xuất đặt tại KCN Tân Đông Hiệp B , Dĩ An, Bình Dương, vị trí giao thông thuận lợi, đội ngũ vận chuyển chuyên nghiệp,  cách cảng xuất khẩu chỉ 20km.' },
            product: { title: 'Giới thiệu sản phẩm', text: 'Giảm giá thành và nâng cao hiệu quả sản xuất , điều chỉnh tính chất nhựa, cải thiện độ cứng và nâng cao sự dẻo dai của sản phẩm, hoàn thiện hơn cho việc phối trộn nguyên liệu.' },
            customize: { title: 'Đội ngũ của chúng tôi', text: 'Đội ngũ kinh doanh xuất sắc của chúng tôi có thể cung cấp cho khách hàng những giải đáp và dịch vụ chuyên nghiệp nhất, giúp khách hàng đáp ứng các tiêu chuẩn cần thiết trong việc sử dụng sản phẩm. Nhà máy được trang bị một phòng thí nghiệm độc lập hoàn chỉnh, có thể số hóa các thuộc tính của sản phẩm theo nhu cầu của khách hàng và cung cấp cho khách hàng các sản phẩm tùy chỉnh và các dịch vụ khác. Trong quá trình sản xuất sử dụng máy trộn áp suất cao , để nguyên liệu thô được phân tán và trộn đều hơn với nhau, đồng thời sử dụng trục vít đôi có  thể thay thế, để đáp ứng các yêu cầu sản xuất của các sản phẩm khác nhau.' },
            news: { title: 'Tin tức hàng đầu' },
            sales: {
                title: 'Our Sales', info: [
                    { name: 'Sean Lin', phone: '+84-917047558', email: 'thuanloiplastic168@gmail.com' },
                    { name: 'Tsao', phone: '+84-919698500', email: 'thuanloiplastic168@gmail.com' },
                    { name: 'Tran Thanh Vinh', phone: '+84-913679977', email: 'thuanloiplastic168@gmail.com' }
                ]
            },
            readMoreButton: 'Đọc thêm'
        },
        ourFactory: {
            title: 'Về chúng tôi',
            introduce: 'Công ty TNHH Thuận Lợi Đài Loan thành lập nhà máy sản xuất tại Việt Nam vào năm 1995, chuyên sản xuất và kinh doanh hạt phụ gia Taical, Nano cal: PP , PE , hạt phụ gia Talc, hạt tăng dai cho nhựa và các loại hạt nhựa kỹ thuật sản xuất theo yêu cầu của khách hàng vv... Cty Thuận Lợi từ nguồn nguyên liệu đầu vào , kiểm soát sản phẩm, yêu cầu về chất lượng đều được quản lý theo quy trình nghiêm ngặt để bảo vệ lợi ích người tiêu dùng sử dụng sản phẩm cty Thuận Lợi. Công ty Thuận Lợi suốt 20 năm phát triển bền vững, với phương châm “trung thành tín nhiệm”  được sự ủng hộ và tin tưởng của khách hàng trên khắp thế giới đã đưa sản phẩm của cty quảng bá trên khắp năm châu, tương lai cty Thuận Lợi sẽ tiếp tục duy trì phương châm chuyên nghiệp, sáng tạo, tích cực phục vụ khách hàng để đôi bên cùng hưởng lợi.',
            history: [
                { year: '1995', text: 'Thành lập cty TNHH Thuận Lợi tại Việt Nam' },
                { year: '1998', text: 'Đưa vào sản xuất và kinh doanh các loại hạt phụ gia Taical' },
                { year: '2003', text: 'Thành lập thêm bộ phận sản xuất và kinh doanh thớt' },
                { year: '2005', text: 'Xây nhà máy tại KCN Tân Đông Hiệp B, Dĩ An , Bình Dương' },
                { year: '2006', text: 'Chính thức hoạt động nhà máy tại KCN Tân Đông Hiệp B ' },
                { year: '2009', text: 'Lần lượt thay đổi máy móc sản xuất ,  cải tiến thành máy trộn cao áp,  máy đùn trục vít đôi' },
                { year: '2014', text: 'Thiết lập phòng thí nghiệm' },
                { year: '2015', text: 'Ký kết hợp tác giao lưu kinh nghiệm sản xuất với trường đại học Quốc Gia Đài Loan ' },
                { year: '2017', text: 'Mở rộng nhà máy , tăng cường thêm dây truyền sản xuất ' },
                { year: '2019', text: 'Trang bị thêm thiết bị phòng thí nghiệm ' },
                { year: '2022', text: 'Thành lập bộ phận kinh doanh bột' }
            ],
            insideFactory: { title: 'Nhà máy', text: 'Nhà máy có 6 dây truyền sản xuất tiêu chuẩn, được lắp đặt hệ thống xử lý và thu hồi bụi , môi trường nhà máy được tối ưu hóa,  hệ thống nhà máy và PCCC phù hợp quy định và tuân thủ theo quy trình ISO. Sản phẩm cty đã được nhiều doanh nghiệp lớn trong nước tin tưởng và công nhận .' },
            laboratory: { title: 'Phòng thí nghiệm', text: 'Chúng tôi có phòng thí nghiệm và khai thác riêng biệt, từ nguồn nguyên liệu đầu vào cho đến đầu ra đều được kiểm tra bằng các thiết bị theo tiêu chuẩn nghiêm ngặt của nhà máy, phòng thí nghiệm có trang bị các loại máy ép nhựa nhỏ, có khả năng chính xác căn cứ theo chỉ số yêu cầu cải thiện tính chất sản phẩm và đạt được theo mong muốn của khách hàng.     ' }
        },
        whyUs: {
            title: 'Tại sao lại chọn chúng tôi',
            product: { title: 'Sản phẩm', text: 'Nguyên liệu đầu vào đều được  kiểm tra chất lượng trước khi nhập kho' },
            factory: { title: 'Nhà máy', text: 'Sản xuất của công ty chúng tôi sử dụng máy trộn tốc độ cao và máy đùn trục vít đôi có thể chuyển đổi, chất lượng sản phẩm ổn định , và hiệu quả hơn cho các sản phẩm do khách hàng đặt theo yêu cầu.' },
            quality: {
                title: 'Yêu cầu chất lượng', textList: [
                    'Nguồn gốc bột, chất lượng ổn định',
                    'Nguyên liệu sản xuất nhập trực tiếp từ nhà cung cấp đáng tin cậy',
                    'Sử dụng phụ gia thân thiện môi trường'
                ]
            },
            safety: {
                textList: [
                    'Người vận hành các khu đều có giấy chứng nhận',
                    'Được trang bị đầy đủ đồng phục, giày bảo hộ, dụng cụ bảo hộ lao động'
                ]
            }
        },
        qualityControl: {
            title: 'Kiểm soát chất lượng',
            contentList: [
                'Có phòng thí nghiệm hoàn chỉnh và các loại hình thiết bị test, nguyên liệu nhập vào phải thông qua kiểm nghiệm',
                'Sản xuất một nửa tự động hóa, chất lượng ổn định',
                'Thí nghiệm dựa theo SOP, đạt được số liệu tính chất vật lý',
                'Thông qua quản lý chất lượng như trên, có được sự tin tưởng của khách hàng về sản phẩm của "Thuận Lợi"'
            ]
        },
        contactUs: {
            title: 'Để lại tin nhắn của bạn cho chúng tôi',
            formColumns: {
                name: { title: 'Tên: ' },
                email: { title: 'E-mail: ' },
                phone: { title: 'Số điện thoại: ' },
                area: { title: 'Quốc gia: ' },
                message: { title: 'Tin nhắn: ' },
            },
            buttons: {
                send: { text: 'Send' }
            }
        },
        productList: {
            total: {
                prefix: 'About',
                suffix: 'results'
            },
            filterButton: { text: 'Filter' }
        },
        /* 產品詳細 */
        productDetail: {
            navigation: {
                root: { text: 'Sản phẩm' }
            },
            infoTable: {
                title: {
                    item: 'Tính chất vật lý',
                    measurementMethod: 'Phương pháp test',
                    unit: 'Đơn vị',
                    value: 'Dữ liệu'
                }
            },
            contactUs: {
                title: 'Bạn có hứng thú không?',
                button: { text: 'Liên hệ với chúng tôi' }
            },
            relativeProduct: {
                title: 'Sản phẩm liên quan'
            }
        },
        news: {
            title: 'Tin tức',
            buttons: {
                readMore: { text: 'Đọc thêm' }
            }
        },
        newsDetail: {
            navigation: {
                root: { text: 'Tin tức' },
                child: { text: 'Content' }
            },
            buttons: {
                back: { text: 'Trở lại danh sách' }
            }
        },
        customize: {
            formColumns: {
                company: { title: 'Tên công ty: ' },
                username: { title: 'Người liên hệ: ' },
                email: { title: 'E-mail: ' },
                productCategory: {
                    title: 'Nguyên liệu sản xuất: ',
                    items: {
                        PP: { text: 'PP' },
                        PE: { text: 'PE' },
                        other: { text: 'Khác' }
                    }
                },
                productLevel: { title: 'Phương thức thành hình sản phẩm ( ép, đùn v..v,): ' },
                hasExperience: {
                    title: 'Có sử dụng các sản phẩm cùng loại không',
                    items: {
                        true: { text: 'Đúng' },
                        false: { text: 'Không' }
                    }
                },
                country: {
                    title: 'Quốc gia: ',
                    items: {
                        taiwan: { text: 'Đài Loan' },
                        vietnam: { text: 'Việt Nam' },
                        other: { text: 'Các nước khác' }
                    }
                },
                remark: { title: 'Ghi chú: ' },
                message: { title: 'Mô tả nội dung: ' }
            },
            buttons: {
                send: { text: 'Gửi đi' }
            }
        },
        /* 全站搜尋 */
        searchResult: {
            title: 'Kết quả tìm kiếm',
            tabs: [
                { title: 'Sản phẩm' },
                {
                    title: 'Tin tức',
                    buttons: {
                        readMore: { text: 'Đọc thêm' }
                    }
                }
            ]
        }
    },
    /* 英文 */
    'en-us': {
        footer: {
            address: { title: 'Address: ', text: 'No35 Lot B2 TanDong Hiep B IZ, Tan Dong Hiep Ward, Di An City, Binh Duong Pro., Vietnam' },
            telephone: { title: 'Phone: ', text: '84-2743728921' },
            email: { title: 'E-mail: ', text: 'thuanloiplastic168@gmail.com' }
        },
        index: {
            title: 'ThuanLoi Plastic',
            company: { title: 'About Us', text: 'Our factory owns complete transportation team and locates at the highly convenient junction in Binh Duong, Vietnam with only twenty kilometers to the port.' },
            product: { title: 'Our Product', text: 'As a kind of natrual powder, calcium carbonate powder and TALC powder is widely used at various industries for its non-toxic and whiteness. In plastic production, they bring advantage to reduce cost of production, increase productivity, change properties of plastic, increase stiffness and toughness, and optimize compounding.' },
            customize: { title: 'Our Team', text: 'Our experienced sales team provides professional opinion and service to assist customers in customizing products. We have our own full laboratory which allows us to digitalize properties data in order to precisely accomplish customized orders.' },
            news: { title: 'Top News' },
            sales: {
                title: 'Our Sales', info: [
                    { name: 'Sean Lin', phone: '+886-919560429', email: 'thuanloiplastic168@gmail.com' },
                    { name: 'Tsao', phone: '+886-952791166', email: 'thuanloiplastic168@gmail.com' },
                    { name: 'Tran Thanh Vinh', phone: '+84-913679977', email: 'thuanloiplastic168@gmail.com' }
                ]
            },
            readMoreButton: 'Read More'
        },
        ourFactory: {
            title: 'Our Factory',
            introduce: 'Eestablished in 1995 in Vietnam, Thuan Loi Plastic Co.,Ltd. specializes in manufacturing and exporting PP, PE calcium carbonate masterbatch, TALC filler masterbatch, toughener, and customized engineering plastic. We practice strict operating procedure starts with the selection of material to the control of quality to ensure our customers recieve assured product. We believe honesty and integrity strengthen partnership with our customers and therefore we are capable of selling products to countries all around the world.',
            history: [
                { year: '1995', text: 'Thuan Loi Plastic Co. Ltd. was founded in Vietnam' },
                { year: '1998', text: 'began to massively manufacture calcium carbonate masterbatch' },
                { year: '2003', text: 'established department of producing industrial plastic cutting board' },
                { year: '2006', text: 'built new factory in the industrial park in Di An city, Bihn Duong' },
                { year: '2007', text: 'relocated headquarter and factory to Di An city' },
                { year: '2009', text: 'imported the intermeshing mixer and twin-screw extruder to strengthen production' },
                { year: '2014', text: 'established laboratory' },
                { year: '2015', text: 'developed industry-academia collaboration with colleges in Taiwan' },
                { year: '2017', text: 'expanded factory and production lines' },
                { year: '2019', text: 'expanded laboratory' },
                { year: '2022', text: 'established department of CACO3 powder and TALC powder sales' }
            ],
            insideFactory: { title: 'Our factory', text: 'Our factory runs six production lines and utilize powder collction system to optimize environment of production. The flow and fire safety system are regulatory compliance and comply with ISO standards. As our honest and reliability, we are the big company\'s favorite.' },
            laboratory: { title: 'Our Laboratory', text: 'We build our own laboratory. All the materials, from import to export, are examined through the lab. It also equips several processing machineries in order to accomplish customized orders.' }
        },
        whyUs: {
            title: 'Why Us?',
            product: { title: 'Our Product', text: 'Every raw material will be comprehensively examined before transferring to storage.' },
            factory: { title: 'Our Factory', text: 'Our factory equips the intermeshing mixer and twin-screw extruder which allow us to stably and effeciently manufacture customized orders.' },
            quality: {
                title: 'Quality Require', textList: [
                    'Stable powder quality comes from local resources',
                    'Materials come from reliable resources',
                    'Using eco-friendly additives'
                ]
            },
            safety: {
                textList: [
                    'All the operators own proper license.',
                    'Uniform, protective shores and tools are required all the time.'
                ]
            }
        },
        qualityControl: {
            title: 'Quality Control',
            contentList: [
                'Our laboratory equips various examination equipment to examine and digitalize data of material.',
                'Semi-automatic production leads to reliable and stable quality.',
                'Finished products properties must be examined by following our standard operation procedure.',
                'With all strict quality control procedure, our customers will receive our reliable and high-quality product!'
            ]
        },
        contactUs: {
            title: 'Leave your message to us',
            formColumns: {
                name: { title: 'Name: ' },
                email: { title: 'E-mail: ' },
                phone: { title: 'Phone: ' },
                area: { title: 'Country：' },
                message: { title: 'Message：' },
            },
            buttons: {
                send: { text: 'Send' }
            }
        },
        productList: {
            total: {
                prefix: 'About',
                suffix: 'results'
            },
            filterButton: { text: 'Filter' }
        },
        /* 產品詳細 */
        productDetail: {
            navigation: {
                root: { text: 'Products' }
            },
            infoTable: {
                title: {
                    item: 'Property',
                    measurementMethod: 'Test Method',
                    unit: 'Unit',
                    value: 'Data'
                }
            },
            contactUs: {
                title: 'Are you interested?',
                button: { text: 'Contact us' }
            },
            relativeProduct: {
                title: 'Related Products'
            }
        },
        news: {
            title: 'News',
            buttons: {
                readMore: { text: 'Read more' }
            }
        },
        newsDetail: {
            navigation: {
                root: { text: 'News' },
                child: { text: 'Content' }
            },
            buttons: {
                back: { text: 'Back to News' }
            }
        },
        customize: {
            formColumns: {
                company: { title: 'Company: ' },
                username: { title: 'Your name: ' },
                email: { title: 'E-mail: ' },
                productCategory: {
                    title: 'Product material: ',
                    items: {
                        PP: { text: 'PP' },
                        PE: { text: 'PE' },
                        other: { text: 'Other' }
                    }
                },
                productLevel: { title: 'Manufacturing process (extrustion, blow molding etc.): ' },
                hasExperience: {
                    title: 'Have you used any similar product?',
                    items: {
                        true: { text: 'Yes' },
                        false: { text: 'No' }
                    }
                },
                country: {
                    title: 'Country: ',
                    items: {
                        taiwan: { text: 'Taiwan' },
                        vietnam: { text: 'Vietnam' },
                        other: { text: 'Other' }
                    }
                },
                remark: { title: 'Note: ' },
                message: { title: 'Message: ' }
            },
            buttons: {
                send: { text: 'Send' }
            }
        },
        /* 全站搜尋 */
        searchResult: {
            title: 'Search Result',
            tabs: [
                { title: 'Products' },
                {
                    title: 'News',
                    buttons: {
                        readMore: { text: 'Read more' }
                    }
                }
            ]
        }
    }
};

export default i18n;