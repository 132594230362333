import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { locationPath } from '../../helpers/pathHelper';
import './SearchBar.scss';

const SearchBar = () => {
    const submitHandler = e => e.preventDefault();
    const doSearch = () => {
        let searchInput = document.querySelector('.searchBar input[type="search"]');

        window.location.href = `${locationPath}#/search-result?keyword=${searchInput.value}`;
        
        searchInput.value = '';
    };

    return (
        <div className='searchBar'>
            <form role="search" onSubmit={submitHandler}>
                <input type="search" ref={element => {(element || {}).onsearch = doSearch}}></input>
            </form>
        </div>
    );
};

export default SearchBar;